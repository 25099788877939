import { Box } from '@mui/material';
const satisfactionEmojiMap = ['😞', '🤔', '😊'];
const answerEmojiMap = ['👎', '🤔', '👍'];
const twoOptionsEmojiMap = ['👎', '👍'];

export default function SurveyEmoji({
  optionIndx,
  survey,
}: {
  selected: boolean;
  optionName: string;
  optionIndx: number;
  survey: any;
}) {
  const isSatisfactionSurvey = survey['satisfaction'] ? true : false;
  return (
    <>
      <Box
        sx={{
          fontSize: '2rem', // Adjust size
          opacity: 0.8,
          transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out', // Smooth transition effect
          '&:hover': {
            opacity: 1, // Fully visible on hover
            transform: 'scale(1.1)',
          },
          cursor: 'pointer',
        }}
      >
        {isSatisfactionSurvey
          ? satisfactionEmojiMap[optionIndx]
          : survey['options'].length === 2
          ? twoOptionsEmojiMap[optionIndx]
          : answerEmojiMap[optionIndx]}
      </Box>
    </>
  );
}
