// @mui
import { Stack, Grid, Box } from '@mui/material';
// components
import Logo from '../../components/logo';
import { SelectMarket } from '../../sections/market';
import LanguagePopover from '../../layouts/dashboard/header/LanguagePopover';

//
import { useAuthContext } from '../../auth/useAuthContext';

import { StyledRoot, StyledSectionCheckout } from './styles';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function MembershipLayout({ children }: Props) {
  const { user, updateMarket } = useAuthContext();
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
        }}
      />

      <Box
        sx={{
          zIndex: 10,
          position: 'absolute',
          top: { xs: '1.5rem', md: '2rem' },
          right: { xs: '1rem', md: '2rem' },
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <LanguagePopover />

        {user?.superadmin && (
          <Box sx={{ ml: 1 }}>
            <SelectMarket user_id={user?.uid} updateMarket={updateMarket} width={60} height={60} />
          </Box>
        )}
      </Box>

      <StyledSectionCheckout>
        <Stack sx={{ width: 1 }}>{children}</Stack>
      </StyledSectionCheckout>
    </StyledRoot>
  );
}
