import axios from 'src/utils/axios';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Editor } from '@tiptap/react';
interface ProcessAiRequestParams {
  selectedText: string;
  context: string;
  action: string;
  userUid?: string;
  customPrompt?: string;
  onStreamData?: (data: string) => void;
  onInsertData?: (data: string) => void;
}
/**
 * Processes AI-generated content for direct insertion into TipTap editor
 * Specifically designed for:
 * 1. Handling HTML-formatted content that's difficult to stream
 * 2. Inserting new content alongside existing text (not replacing)
 * 3. Complex formatting operations that require complete content before insertion
 *
 * @param selectedText - The text selected in the editor or context for AI
 * @param context - Additional context for the AI to understand the request
 * @param action - The type of operation to perform (e.g., 'format', 'enhance', 'translate')
 * @param userUid - Optional user identifier for tracking/billing
 * @param customPrompt - Optional custom instructions for the AI
 * @param onInsertData - Callback function to handle the processed content
 */
export const processAiInsert = async ({
  selectedText,
  context,
  action,
  userUid,
  customPrompt,
  onInsertData,
}: ProcessAiRequestParams) => {
  try {
    // Send request to non-streaming endpoint for complex content processing
    const response = await axios.post('/api/ai_composer_insert', {
      userUid: userUid || '',
      selectedText,
      context,
      action,
      customPrompt: customPrompt || '',
    });

    // Handle the processed content through callback if provided
    if (onInsertData) {
      onInsertData(response.data.response.output_field);
    }
  } catch (error) {
    console.error('Error processing AI insert:', error);
    throw error;
  }
};

export const processAiRequest = async ({
  selectedText,
  action,
  userUid,
  customPrompt,
  onStreamData,
}: ProcessAiRequestParams) => {
  return new Promise((resolve, reject) => {
    const baseUrl = process.env.REACT_APP_HOST_API_KEY || '';
    const url = `${baseUrl}/api/ai_composer`;

    const queryParams = new URLSearchParams({
      user_uid: userUid || '',
      text: selectedText,
      action,
      custom_prompt: customPrompt || '',
    });

    const eventSource = new EventSourcePolyfill(`${url}?${queryParams}`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
      },
    });

    let messageString = '';
    const timeoutDuration = 100000; // 100 seconds
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const timeoutHandler = () => {
      console.log('EventSource request timed out');
      eventSource.close();
      reject(new Error('Request timed out'));
    };

    const clearEventSourceTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    // Handle incoming messages from the server
    eventSource.onmessage = (event) => {
      clearEventSourceTimeout();

      if (event.data === 'ENDSTREAM') {
        eventSource.close();
        resolve(messageString);
      } else if (event.data === 'PAUSE') {
        messageString += '\n';
      } else {
        messageString += event.data;
        if (onStreamData) {
          onStreamData(messageString);
        }
      }

      timeoutId = setTimeout(timeoutHandler, timeoutDuration);
    };

    eventSource.onerror = (error) => {
      console.error('EventSource encountered an error:', error);
      clearEventSourceTimeout();
      eventSource.close();

      const status = (error as any).status || (error.target as XMLHttpRequest)?.status;
      if (status === 429) {
        reject(new Error('Limit reached'));
      } else {
        reject(new Error('An unexpected error occurred'));
      }
    };

    // Start initial timeout
    timeoutId = setTimeout(timeoutHandler, timeoutDuration);
  });
};

/**
 * Handles the insertion of streaming content into a TipTap editor while maintaining document format
 * @param editor - The TipTap editor instance
 * @param content - The new content chunk to be inserted
 * @param state - Object containing the state of the streaming operation
 * @returns Object containing the inserted content
 */
export const handleStreamingContent = (
  editor: Editor,
  content: string,
  state: {
    previousContent: string; // The content that was previously inserted
    isFirstChunk: boolean; // Whether this is the first chunk of streaming content
    from: number; // Starting position of the selection/insertion
    to: number; // Ending position of the selection/insertion
    parentDepth: number; // Depth of the parent node in the document tree
  }
) => {
  const { previousContent, isFirstChunk, from, to } = state;

  if (isFirstChunk) {
    // For the first chunk, replace the selected text with new content
    editor
      .chain()
      .focus()
      .setTextSelection({ from, to }) // Select the target range
      .deleteSelection() // Remove the selected text
      .insertContent(content) // Insert the new content
      .run();
  } else {
    // For subsequent chunks, replace the previous chunk with the updated content
    editor
      .chain()
      .focus()
      .setTextSelection({
        from,
        to: from + previousContent.length, // Select only the previous chunk
      })
      .deleteSelection() // Remove the previous chunk
      .insertContent(content) // Insert the updated content
      .run();
  }
  return { content };
};

export function sanitizeContent(content: string) {
  return content
    .replace(/```html/g, '')
    .replace(/'''html/g, '')
    .replace(/<>/g, '')
    .replace(/\s+/g, ' ')
    .trim();
}

export const getSelectedText = (editor: Editor) => {
  if (!editor) return '';
  const { from, to } = editor.state.selection;

  const $from = editor.state.selection.$from;
  const parentDepth = $from.depth;
  const line = {
    start: $from.start(parentDepth),
    end: $from.end(parentDepth),
  };

  return {
    text: editor.state.doc.textBetween(from, to, '\n'),
    fullLine: editor.state.doc.textBetween(line.start, line.end, '\n'),
    from,
    to,
    parentDepth,
  };
};
