import numeral from 'numeral';

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue) {
  const format = number
    ? numeral(number).format('0,0.00').replace(',', '#').replace('.', ',').replace('#', '.')
    : '0,00';

  return format;
}

export function fCurrencyShort(number: InputValue) {
  const format = number
    ? numeral(number).format('0,0').replace(',', '#').replace('.', ',').replace('#', '.')
    : '0,00';

  return format;
}

export function fPercent(number: InputValue, decimals = true) {
  if (decimals) {
    const format = number ? numeral(Number(number) / 100).format('0.0%') : '';
    return result(format, '.0');
  }

  const format = number ? numeral(Number(number) / 100).format('0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '0';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

export function fHours(number: InputValue) {
  if (number === null || number === undefined) return '0';
  // Round to 2 decimal places and remove trailing zeros
  const rounded = numeral(number).format('0.00');
  return result(rounded, '.00');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
