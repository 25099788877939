import { useState, useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
// sections
import { useAuthContext } from '../../../../auth/useAuthContext';
import { useDispatch } from '../../../../redux/store';
import { generateExercisesForTimeline } from '../../../../redux/slices/lesson';
// @mui
import {
  Box,
  AccordionSummary,
  Stack,
  Accordion,
  AccordionDetails,
  Typography,
  Button,
  TextField,
  IconButton,
  Divider,
} from '@mui/material';

import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

// components
import { useLocales } from '../../../../locales';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../../../components/iconify/Iconify';
import { ITimeline, ILessonAdditionalExercises } from '../../../../@types/lesson';
import Latex from 'react-latex-next';
import RoleBasedGuard from 'src/auth/RoleBasedGuard';
/**
 * This functional component renders the LessonDetailsPage, which displays lesson Detailss.
 *
 * @component
 * @returns {JSX.Element} The rendered LessonDetailsPage component.
 */
type Props = {
  timeLineEditable: ITimeline[];
  setTimeLineEditable: Dispatch<SetStateAction<ITimeline[]>>;
  onSave: () => void;
  id: string | number;
  additionalExercises?: ILessonAdditionalExercises[];
  handleGenerateExercises: (message: string) => void;
  handleOpenRegenerateTimeline: () => void;
  isInModal?: boolean;
  changedEditMode?: boolean;
};
export default function LessonTimeLine({
  timeLineEditable,
  setTimeLineEditable,
  onSave,
  id,
  additionalExercises = [],
  handleGenerateExercises,
  handleOpenRegenerateTimeline,
  isInModal = false,
  changedEditMode = false,
}: Props) {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const theme = useTheme();
  const [openAccordion, setOpenAccordion] = useState(false);
  const [accordionIndex, setAccordionIndex] = useState<null | number>(null);
  const [showAllExercises, setShowAllExercises] = useState(false);
  const [editMode, setEditMode] = useState<{
    allow: boolean;
    index: null | number;
    initiated: boolean;
  }>({
    allow: false,
    index: null,
    initiated: false,
  });
  const [viewAnswer, setViewAnswer] = useState<{ allow: boolean; index: null | number }>({
    allow: false,
    index: null,
  });
  const [showAnswer, setShowAnswer] = useState<{ allow: boolean; index: null | number }>({
    allow: false,
    index: null,
  });

  const handleGenerateExercisesForTimeline = (index: number) => {
    dispatch(generateExercisesForTimeline(id, index));
  };

  useEffect(() => {
    if (changedEditMode) {
      setEditMode({ allow: false, index: null, initiated: true });
    }
  }, [changedEditMode]);

  return (
    <Box sx={{ marginLeft: 2, scrollBehavior: 'auto' }}>
      <Timeline
        sx={{
          p: 0,
          m: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.1,
          },
        }}
      >
        {timeLineEditable.map((item, index) => {
          const firstTimeline = index === 0;
          const lastTimeline = index === timeLineEditable.length - 1;

          return (
            <>
              <TimelineItem key={index}>
                <TimelineOppositeContent color="text.secondary" sx={{ width: 'fit-content' }}>
                  {firstTimeline && <Typography variant="caption">Minutes</Typography>}
                  {editMode.allow && editMode.index === index ? (
                    <TextField
                      type="number"
                      inputProps={{ style: { textAlign: 'center' } }}
                      onChange={(e) => {
                        const newArray = [...timeLineEditable];
                        newArray[index].start_time_topic = parseInt(e.target.value);
                        setTimeLineEditable(newArray);
                      }}
                      value={timeLineEditable[index].start_time_topic}
                    ></TextField>
                  ) : (
                    <>
                      <Typography variant="body1">{item.start_time_topic} </Typography>
                    </>
                  )}
                </TimelineOppositeContent>

                <TimelineSeparator color="primary">
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                  <Accordion
                    sx={{
                      width: '100%',
                      borderRadius: theme.palette.borders.borderRadius,
                      '&.Mui-expanded': {
                        borderRadius: theme.palette.borders.borderRadius,
                        border: theme.palette.borders.border,
                        boxShadow: theme.palette.borders.boxShadow,
                      },
                    }}
                    expanded={
                      (editMode.allow && editMode.index === index) ||
                      (openAccordion && accordionIndex === index)
                    }
                    onChange={() => {
                      setOpenAccordion(!openAccordion);
                      setAccordionIndex(index);
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      expandIcon={editMode.allow ? null : <Iconify icon={'ph:caret-circle-down'} />}
                      sx={{
                        borderRadius: '25px',
                        width: '100%',
                      }}
                    >
                      <Stack
                        direction="column"
                        spacing={4}
                        alignItems="stretch"
                        sx={{
                          width: '100%',
                          mr: 2,
                        }}
                      >
                        {/* Title */}
                        {editMode.allow && editMode.index === index ? (
                          <TextField
                            fullWidth
                            sx={{ width: '100%', mb: 2 }}
                            onChange={(e) => {
                              const newArray = [...timeLineEditable];
                              newArray[index] = { ...newArray[index], topic: e.target.value };
                              // console.log(newArray);
                              setTimeLineEditable(newArray);
                            }}
                            value={timeLineEditable[index].topic || ''}
                            label={`${translate('lessonPlans.enterTopics')}`}
                          ></TextField>
                        ) : (
                          <Typography variant="body1">
                            {' '}
                            <Latex>{item.topic || ''}</Latex>
                          </Typography>
                        )}
                        {/* Description */}
                        {editMode.allow && editMode.index === index ? (
                          <TextField
                            fullWidth
                            sx={{ width: '100%' }}
                            onChange={(e) => {
                              const newArray = [...timeLineEditable];
                              newArray[index] = {
                                ...newArray[index],
                                description: e.target.value,
                              };
                              // console.log(newArray);
                              setTimeLineEditable(newArray);
                            }}
                            multiline
                            rows={3}
                            value={timeLineEditable[index].description}
                            label={`${translate('lessonPlans.enterDescription')}`}
                          ></TextField>
                        ) : (
                          <>
                            <Box
                              sx={{
                                color: 'text.disabled',
                                typography: 'caption',
                                mt: '0px !important',
                              }}
                            >
                              <Latex>{item.description}</Latex>
                            </Box>
                          </>
                        )}
                      </Stack>
                    </AccordionSummary>
                    {/* Exercises and description */}
                    <AccordionDetails
                      sx={{
                        borderRadius: '25px',
                      }}
                    >
                      <Stack direction={'column'} spacing={2}>
                        <Stack direction={'row'}>
                          <Iconify icon="ph:barbell" sx={{ mr: 1 }}></Iconify>
                          <Typography variant="body1" sx={{ mb: 2 }}>
                            {`${translate('lessonPlans.exercises')}`}:{' '}
                          </Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={editMode.allow ? 6 : 2}>
                          {item.exercises.map((exercise, indexExercise) => {
                            return (
                              <Stack
                                direction={'column'}
                                key={indexExercise}
                                spacing={editMode.allow ? 4 : 2}
                              >
                                {editMode.allow && editMode.index === index ? (
                                  <>
                                    <Stack
                                      direction={'row'}
                                      justifyContent={'flex-start'}
                                      spacing={1}
                                    >
                                      <Typography variant="subtitle2" sx={{ mb: 2 }}>
                                        {indexExercise + 1}
                                        {`. `}
                                      </Typography>
                                      <TextField
                                        fullWidth
                                        sx={{ mb: 4 }}
                                        onChange={(e) => {
                                          const newArray = [...timeLineEditable]; // Creates a shallow copy of timeLineEditable
                                          const newExercises = [...newArray[index].exercises]; // Creates a shallow copy of exercises array
                                          newExercises[indexExercise] = e.target.value; // Updates the specific exercise

                                          newArray[index] = {
                                            ...newArray[index],
                                            exercises: newExercises, // Sets the updated exercises array back into the object
                                          };

                                          setTimeLineEditable(newArray); // Updates the state with the new array
                                        }}
                                        multiline
                                        maxRows={3}
                                        value={timeLineEditable[index].exercises[indexExercise]}
                                        label={`${translate('lessonPlans.enterExercise')}`}
                                      ></TextField>

                                      {/* Button to delete exercise from array */}
                                      <RoleBasedGuard roles={['teacher', 'admin']}>
                                        <Button
                                          size="small"
                                          onClick={() => {
                                            const newArray = timeLineEditable.map((item) => ({
                                              ...item,
                                              exercises: [...item.exercises],
                                              answers: [...item.answers],
                                            }));
                                            const newExercises = [...newArray[index].exercises];
                                            const newAnswers = [...newArray[index].answers];
                                            newExercises.splice(indexExercise, 1);
                                            newAnswers.splice(indexExercise, 1);
                                            newArray[index].answers = newAnswers;
                                            newArray[index].exercises = newExercises;
                                            setTimeLineEditable([...newArray]);
                                          }}
                                          variant="outlined"
                                          color="error"
                                        >
                                          <Iconify icon="ph:minus"></Iconify>
                                        </Button>
                                      </RoleBasedGuard>
                                    </Stack>
                                    <Stack direction={'row'} justifyContent={'flex-start'}>
                                      <TextField
                                        fullWidth
                                        onChange={(e) => {
                                          const newArray = [...timeLineEditable]; // Creates a shallow copy of timeLineEditable
                                          const newAnswers = [...newArray[index].answers]; // Creates a shallow copy of answers array
                                          newAnswers[indexExercise] = e.target.value; // Updates the specific answer

                                          newArray[index] = {
                                            ...newArray[index],
                                            answers: newAnswers, // Sets the updated answer array back into the object
                                          };

                                          setTimeLineEditable(newArray); // Updates the state with the new array
                                        }}
                                        value={timeLineEditable[index].answers[indexExercise]}
                                        multiline
                                        maxRows={2}
                                        label={`${translate('lessonPlans.enterAnswer')}`}
                                      ></TextField>
                                    </Stack>
                                  </>
                                ) : (
                                  <>
                                    <Typography variant="subtitle2">
                                      {indexExercise + 1}
                                      {`. `}
                                      <Latex>{exercise || ''}</Latex>
                                      <IconButton
                                        color="info"
                                        size="small"
                                        onClick={() => {
                                          if (viewAnswer.index === indexExercise) {
                                            setViewAnswer({ allow: false, index: null });
                                          } else {
                                            setViewAnswer({ allow: true, index: indexExercise });
                                          }
                                        }}
                                        sx={{ ml: 1, mb: 1 }} // Add some margin to the left of the button
                                      >
                                        {viewAnswer.allow && viewAnswer.index === indexExercise ? (
                                          <Iconify
                                            icon="ph:eye-slash"
                                            sx={{ color: theme.palette.common.black }}
                                            width={20}
                                          />
                                        ) : (
                                          <Iconify
                                            icon="ph:eye"
                                            sx={{ color: theme.palette.common.black }}
                                            width={20}
                                          />
                                        )}
                                      </IconButton>
                                    </Typography>
                                    <Stack direction={'row'}>
                                      {viewAnswer.allow && viewAnswer.index === indexExercise && (
                                        <Typography variant="caption" sx={{ mb: 2 }}>
                                          <Iconify
                                            icon="ph:check-circle"
                                            sx={{
                                              verticalAlign: 'middle',
                                              color: theme.palette.success.main,
                                            }}
                                          ></Iconify>
                                          <Latex> {item.answers[indexExercise]}</Latex>
                                        </Typography>
                                      )}
                                      {/* Button to enable and disable to show answer */}
                                    </Stack>
                                  </>
                                )}
                              </Stack>
                            );
                          })}
                        </Stack>
                        {/* Submit edit button */}
                        <RoleBasedGuard roles={['teacher', 'admin']}>
                          {editMode.allow && index === editMode.index ? (
                            <Stack direction={'row'} spacing={2} mt={2}>
                              <Button
                                size="small"
                                onClick={() => {
                                  const newArray = [...timeLineEditable];
                                  newArray.splice(index, 1);
                                  setTimeLineEditable(newArray);
                                  setEditMode({ allow: false, index: null, initiated: true });
                                }}
                                variant="outlined"
                                color="error"
                              >
                                <Iconify color="error" icon="ph:trash"></Iconify>
                              </Button>

                              <Button
                                size="small"
                                onClick={() => {
                                  // Create a deep copy of the current state to avoid mutating it directly
                                  const newArray = timeLineEditable.map((item) => ({
                                    ...item,
                                    exercises: [...item.exercises],
                                    answers: [...item.answers],
                                  }));

                                  // add new empty strings to the exercises and answers arrays
                                  newArray[index].exercises.push('');
                                  newArray[index].answers.push('');

                                  // Update the state with the newly modified array
                                  setTimeLineEditable(newArray);
                                }}
                                variant="outlined"
                                color="success"
                              >
                                <Iconify icon="ph:plus"></Iconify>
                              </Button>
                              <Button
                                size="small"
                                onClick={() => {
                                  if (user?.teacher || user?.admin) {
                                    setEditMode({ allow: false, index: null, initiated: true });
                                  }
                                }}
                                variant="contained"
                                color="success"
                              >
                                <Iconify color="success" icon="ph:pencil-slash"></Iconify>
                              </Button>
                            </Stack>
                          ) : (
                            <Stack direction={'row'} spacing={2} mt={2}>
                              {/* Delete button */}
                              <Button
                                size="small"
                                onClick={() => {
                                  const newArray = [...timeLineEditable];
                                  newArray.splice(index, 1);
                                  setTimeLineEditable(newArray);
                                  setEditMode({ allow: false, index: null, initiated: true });
                                }}
                                variant="outlined"
                                color="error"
                              >
                                <Iconify color="error" icon="ph:trash"></Iconify>
                              </Button>
                              <Button
                                size="small"
                                onClick={() => {
                                  if (user?.teacher || user?.admin) {
                                    if (editMode.allow && index === editMode.index) {
                                      setEditMode({ allow: false, index: null, initiated: true });
                                    } else {
                                      setEditMode({ allow: true, index: index, initiated: true });
                                    }
                                  }
                                }}
                                sx={{ marginLeft: 2 }}
                                variant="outlined"
                                color="warning"
                              >
                                <Iconify color="warning" icon="ph:pencil"></Iconify>
                              </Button>
                              <Button
                                size="small"
                                onClick={() => {
                                  handleGenerateExercisesForTimeline(index);
                                }}
                                variant="outlined"
                                color="primary"
                              >
                                <Iconify color="primary" icon="ph:plus"></Iconify>
                                <Typography
                                  sx={{ lineHeight: 1, marginLeft: '2px' }}
                                  variant="caption"
                                >
                                  {String(translate('lessonPlans.generateExercises'))}
                                </Typography>
                              </Button>
                            </Stack>
                          )}
                        </RoleBasedGuard>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </TimelineContent>
              </TimelineItem>

              {additionalExercises?.length > 0 &&
                (timeLineEditable.length === index + 1 || timeLineEditable.length === 0) && (
                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary" sx={{ width: 'fit-content' }}>
                      <Typography variant="caption">
                        {`${translate('lessonPlans.exercises')}`}
                      </Typography>
                    </TimelineOppositeContent>

                    <TimelineSeparator>
                      <TimelineDot color="warning" />
                      <TimelineConnector />
                    </TimelineSeparator>

                    <TimelineContent>
                      <Accordion
                        sx={{
                          width: '100%',
                          borderRadius: theme.palette.borders.borderRadius,
                          '&.Mui-expanded': {
                            borderRadius: theme.palette.borders.borderRadius,
                            border: theme.palette.borders.border,
                            boxShadow: theme.palette.borders.boxShadow,
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<Iconify icon={'ph:caret-circle-down'} />}
                          sx={{
                            borderRadius: '25px',
                            width: '100%',
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={2}
                            alignItems="stretch"
                            sx={{
                              width: '100%',
                              mr: 2,
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: theme.palette.text.secondary }}
                            >
                              {`${translate('lessonPlans.additionalExercises')}`}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: 'text.disabled',
                                mt: '0px !important',
                              }}
                            >
                              {`${translate('lessonPlans.additionalExercisesDescription')}`}
                            </Typography>
                          </Stack>
                        </AccordionSummary>

                        <AccordionDetails sx={{ borderRadius: '25px' }}>
                          <Stack direction="column" spacing={2}>
                            {additionalExercises.map((exercise, key) => (
                              <Box key={key}>
                                <Stack
                                  onClick={() =>
                                    setShowAnswer((prev) => ({ allow: !prev.allow, index: key }))
                                  }
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{ typography: 'body1', cursor: 'pointer', py: 1 }}
                                >
                                  <Typography variant="body1">
                                    <Latex>{exercise.exercise || ''}</Latex>
                                  </Typography>
                                </Stack>
                                {showAnswer.allow && showAnswer.index === key ? (
                                  <Iconify
                                    sx={{
                                      color: theme.palette.common.black,
                                    }}
                                    icon="ph:eye-slash"
                                    width={20}
                                  />
                                ) : (
                                  <Iconify
                                    sx={{
                                      color: theme.palette.common.black,
                                    }}
                                    icon="ph:eye"
                                    width={20}
                                  />
                                )}
                                {showAnswer.allow && showAnswer.index === key && (
                                  <Box sx={{ typography: 'caption' }}>
                                    <Iconify
                                      icon="ph:check-circle"
                                      sx={{
                                        verticalAlign: 'middle',
                                        color: theme.palette.success.main,
                                      }}
                                    ></Iconify>
                                    <Latex>{`${exercise.answer || ''}`}</Latex>
                                  </Box>
                                )}
                                <Divider sx={{ mt: 2 }} />
                              </Box>
                            ))}

                            <Button
                              onClick={() =>
                                handleGenerateExercises(
                                  'Generate harder and complex exercises focused on each topic of the lesson'
                                )
                              }
                              variant="outlined"
                              color="primary"
                              sx={{ mr: 2, width: 'fit-content' }}
                            >
                              {String(translate('lessonPlans.generateAdditionalExercises'))}
                            </Button>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </TimelineContent>
                  </TimelineItem>
                )}
              <RoleBasedGuard roles={['teacher', 'admin']}>
                {!editMode.allow &&
                  (timeLineEditable.length === index + 1 || timeLineEditable.length === 0) && (
                    <Stack direction="row" spacing={1} marginTop={2} marginLeft={10}>
                      <Button
                        size="small"
                        startIcon={<Iconify icon="ph:plus" />}
                        onClick={() => {
                          setTimeLineEditable([
                            ...timeLineEditable,
                            {
                              start_time_topic: timeLineEditable[index].start_time_topic + 20,
                              description: '',
                              topic: '',
                              exercises: [''],
                              answers: [''],
                            },
                          ]);
                          setEditMode({ allow: true, index: index + 1, initiated: true });
                        }}
                        variant="outlined"
                        color="success"
                      >
                        {`${translate('lessonPlans.addToTimeline')}`}
                      </Button>
                      {isInModal && (
                        <Button
                          startIcon={<Iconify icon="ph:wrench" />}
                          size="small"
                          variant="outlined"
                          color="primary"
                          sx={{ mr: 2 }}
                          onClick={handleOpenRegenerateTimeline}
                        >
                          {String(translate('lessonPlans.improveLessonPlan'))}
                        </Button>
                      )}
                      {editMode.initiated && (
                        <Button
                          startIcon={<Iconify icon="ph:check" />}
                          onClick={onSave}
                          variant="contained"
                          color="success"
                          size="small"
                          sx={{ mt: 2 }}
                        >
                          {`${translate(`lessonPlans.save`)}`}
                        </Button>
                      )}
                    </Stack>
                  )}
              </RoleBasedGuard>
            </>
          );
        })}
      </Timeline>
    </Box>
  );
}
