import axios from 'axios';
import { HOST_API_KEY } from '../config-global';
import { setSession } from '../auth/utils';
// import localStorageAvailable from './localStorageAvailable';
// config

// ----------------------------------------------------------------------

/** creates a new instance of the Axios library with a specified configuration.
 * In this case, the configuration sets the baseURL property to HOST_API_KEY,
 * which is the base URL for all requests made using this Axios instance.
 */

const axiosInstance = axios.create({
  baseURL: HOST_API_KEY,
  withCredentials: true,
});
/** adds a request interceptor to the Axios instance.
 * This interceptor is a function that will be executed for every HTTP request received by the Axios instance.
 * The function takes two arguments: request and error.
 * If the request is successful, it returns the request object.
 * It adds the market_id to the request object
 * If the request contains an error. It returns a rejected Promise with the error data or a default error message.
 */
axiosInstance.interceptors.request.use(
  (config) => {
    const market_id = localStorage.getItem('market_id') || sessionStorage.getItem('market_id');
    if (localStorage.getItem('super_admin') || sessionStorage.getItem('super_admin') || false) {
      config.headers['Super-Admin'] = true;
    }
    config.headers['Market-Id'] = market_id;
    // Ensure withCredentials is always true for all requests
    config.withCredentials = true;
    return config;
  },
  async (error) => {
    // console.log('test');
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);
/** adds a response interceptor to the Axios instance.
 * This interceptor is a function that will be executed for every HTTP response received by the Axios instance.
 * The function takes two arguments: response and error.
 * If the response is successful, it returns the response object.
 * If the response contains an error. If the error is a 401 authentication, we try to refresh the authentication token.
 * Otherwise it returns a rejected Promise with the error data or a default error message.
 */
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status, config } = error.response;

    // If there is an authentication error, we try to refresh the access token.

    if (status === 401 && config.url !== '/api/tokens') {
      const access_token = localStorage.getItem('accessToken')
        ? localStorage.getItem('accessToken')
        : sessionStorage.getItem('accessToken');

      const refreshResponse = await axiosInstance.put(
        '/api/tokens',
        {
          access_token: access_token,
        },
        {
          withCredentials: true, // Include with credentials so Refresh Token is send securely as a cookie
        }
      );
      const { access_token: accessToken } = refreshResponse.data;
      // console.log('accessToken');
      // Update local storage with new access token and axios default header or if access token is Null then remove the old access token from local storage
      setSession(accessToken);
      // console.log(config);
      if (refreshResponse.status === 200) {
        // Ensure the retry request also has withCredentials set to true
        config.withCredentials = true;
        return axiosInstance.request(config);
      }

      return Promise.reject((error.response && error.response.data) || 'Something went wrong');
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
