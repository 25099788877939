import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

// @mui
import {
  IconButton,
  Typography,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Slider,
  Tooltip,
} from '@mui/material';

// components
import { useSnackbar } from '../../../components/snackbar';
import { fCurrency } from '../../../utils/formatNumber';
import { getCurrencyLabel } from '../../../assets/data/currency';

import Iconify from '../../../components/iconify';
import { IInvoice } from '../../../@types/invoice';
import { useLocales } from 'src/locales';

// redux
import { useDispatch } from '../../../redux/store';
import { orderExtraCredits } from '../../../redux/slices/invoice';

type Props = {
  width?: number;
  height?: number;
  latestCustomerOrder: IInvoice | null;
  navbar: boolean;
  outlined?: boolean;
  color?:
    | 'primary'
    | 'error'
    | 'success'
    | 'warning'
    | 'info'
    | 'inherit'
    | 'secondary'
    | undefined;
  isDashboard?: boolean;
};

export default function ExtraCredits({
  latestCustomerOrder,
  navbar,
  outlined = true,
  color = 'primary',
  isDashboard = false,
}: Props) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [quantity, setQuantity] = useState(10);
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const handleOrderedExtraCredits = async () => {
    try {
      if (latestCustomerOrder) {
        if (quantity < 1 || quantity > 20) {
          enqueueSnackbar(String(translate('snackBar.genericError')), { variant: 'error' });
        }
        const response = await dispatch(orderExtraCredits(latestCustomerOrder?.id, quantity));
        setOpenDialog(true);
        if (response) {
          // Success case
        } else {
          enqueueSnackbar(String(translate('snackBar.genericError')), { variant: 'error' });
        }
      } else {
        enqueueSnackbar(String(translate('snackBar.genericError')), { variant: 'error' });
        setOpenDialog(true);
      }
    } catch (err) {
      enqueueSnackbar(String(translate('snackBar.genericError')), { variant: 'error' });
      setOpenDialog(true);
    }
  };

  if (!latestCustomerOrder) {
    return <></>;
  }

  const handleSliderChange = (event: Event, value: number | number[]) => {
    if (typeof value === 'number') {
      setQuantity(value);
    }
  };

  const totalPrice = quantity * (latestCustomerOrder?.unit_price || 0);

  return (
    <>
      {isDashboard ? (
        <Button
          variant="text"
          startIcon={<Iconify icon="ph:plus-circle" />}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            p: 1,
            borderRadius: 1,
            color: 'text.primary',
            borderColor: 'divider',
            gap: 2,
            width: '100%',
          }}
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <Typography
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            variant="subtitle1"
          >
            {`${translate('customer.membership.buyExtraCredits')}`}
            <Typography variant="caption">
              {`${translate('customer.membership.buyExtraCreditsTooltip')}`}
            </Typography>
          </Typography>
        </Button>
      ) : (
        <Tooltip title={`${translate('customer.membership.buyExtraCreditsTooltip')}`} arrow>
          <Button
            onClick={() => {
              setOpenDialog(true);
            }}
            variant={outlined ? 'outlined' : 'contained'}
            color={color}
            startIcon={<Iconify icon="ph:basket" />}
          >
            {`${translate('customer.membership.buyExtraCredits')}`}
          </Button>
        </Tooltip>
      )}

      <Dialog fullWidth maxWidth="md" open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {`${translate('customer.membership.buyExtraCredits')}`}
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            color="error"
            onClick={() => setOpenDialog(false)}
          >
            <Iconify icon="ic:sharp-close" />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: '100%', p: 3 }}>
            <Box sx={{ position: 'relative', mt: 3, mb: 4 }}>
              <Slider
                value={quantity}
                aria-labelledby="input-slider"
                step={1}
                marks={[
                  { value: 1, label: '1' },
                  { value: 5, label: '5' },
                  { value: 10, label: '10' },
                  { value: 15, label: '15' },
                  { value: 20, label: '20' },
                ]}
                min={1}
                max={20}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                componentsProps={{
                  valueLabel: {
                    className: 'value-label',
                  },
                }}
                sx={{
                  height: 8,
                  padding: '15px 0',
                  '& .MuiSlider-valueLabel': {
                    transform: 'translateY(-35px) scale(1) !important',
                    backgroundColor: 'transparent',
                    '&:before': {
                      display: 'none',
                    },
                    '& *': {
                      background: 'transparent',
                      color: 'transparent',
                    },
                  },
                  '& .MuiSlider-thumb': {
                    width: 24,
                    height: 24,
                    transition: '0.2s cubic-bezier(.47,1.64,.41,.8)',
                    '&:before': {
                      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                    },
                    '&:hover, &.Mui-focusVisible': {
                      boxShadow: `0px 0px 0px 8px ${
                        theme.palette.mode === 'dark'
                          ? 'rgb(255 255 255 / 16%)'
                          : 'rgb(0 0 0 / 16%)'
                      }`,
                    },
                    '&.Mui-active': {
                      width: 32,
                      height: 32,
                    },
                  },
                  '& .MuiSlider-rail': {
                    opacity: 0.5,
                    backgroundColor: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
                  },
                  '& .MuiSlider-track': {
                    border: 'none',
                    height: 8,
                    backgroundColor: theme.palette.primary.main,
                  },
                  '& .MuiSlider-mark': {
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                    opacity: 1,
                    '&.MuiSlider-markActive': {
                      opacity: 1,
                      backgroundColor: theme.palette.primary.main,
                      border: `1px solid ${theme.palette.primary.main}`,
                    },
                  },
                  '& .MuiSlider-markLabel': {
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    top: 24,
                    color: theme.palette.text.secondary,
                    '&.MuiSlider-markLabelActive': {
                      fontWeight: 600,
                      color: theme.palette.text.primary,
                    },
                  },
                }}
              />

              {/* Custom value bubble tooltip */}
              <Box
                sx={{
                  position: 'absolute',
                  top: -38,
                  left: `calc(${((quantity - 1) / 19) * 100}% - 20px)`,
                  transition: 'left 0.2s ease-out',
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    border: 'none',
                    padding: '4px 8px',
                    borderRadius: '4px',
                    fontWeight: 'bold',
                    minWidth: '40px',
                    height: '32px',
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      width: 0,
                      height: 0,
                      bottom: -6,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      borderLeft: '6px solid transparent',
                      borderRight: '6px solid transparent',
                      borderTop: `6px solid ${theme.palette.primary.main}`,
                    },
                  }}
                >
                  {quantity}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {`${translate('customer.membership.hourlyPrice')}`}:
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {`${fCurrency(latestCustomerOrder?.unit_price || 0)} 
                ${getCurrencyLabel(latestCustomerOrder?.currency)}`}
              </Typography>
            </Box>

            <Typography
              variant="subtitle2"
              sx={{
                fontStyle: 'italic',
                color: theme.palette.error.main,
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
              }}
            >
              <Iconify icon="ph:info" width={16} />
              {`${translate('customer.membership.expires4weeks')}`}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3, justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5 }}>
              {`${translate('customer.membership.totalPrice')}`}:
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              {`${fCurrency(totalPrice || 0)} ${getCurrencyLabel(latestCustomerOrder?.currency)}`}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setOpenDialog(false);
              handleOrderedExtraCredits();
            }}
            sx={{
              px: 3,
              py: 1,
              borderRadius: 2,
              fontSize: '1rem',
            }}
          >
            {`${translate('customer.membership.goToPayment')}`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
