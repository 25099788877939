import i18n from 'src/locales/i18n';
import { updateInitialMessage } from './slices/ai'; // Import the action to update the message

export function setupLanguageChangeListener(dispatch: any) {
  i18n.on('languageChanged', () => {
    const translatedMessage = i18n.t('ai.initialMessage');
    console.log(translatedMessage);
    dispatch(updateInitialMessage(translatedMessage));
  });
}
