import { useState, useEffect } from 'react';
import { TextField, Autocomplete, Box, Typography, Chip } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import Iconify from 'src/components/iconify/Iconify';
import { useLocales } from 'src/locales';

type Subject = {
  label: string;
  icon: string;
  value: string;
  disabled: boolean;
  translateLabel: string;
  translateDisabled: string;
  soon?: boolean;
};

type Props = {
  subjects: Subject[];
  subject: Subject;
  isMobile: boolean;
  setSubject: (value: Subject) => void;
  setShowMathInput: (value: boolean) => void;
  setInput: (value: string) => void;
};

export default function AiSubjectSelect({
  subjects,
  isMobile,
  setShowMathInput,
  setSubject,
  subject,
  setInput,
}: Props) {
  const theme = useTheme();
  const { translate } = useLocales();
  const [isEmpty, setIsEmpty] = useState(false);

  const mathInputSubjects = ['math', 'physics/chemistry'];

  useEffect(() => {
    setShowMathInput(mathInputSubjects.includes(subject.value));
  }, [subject]);

  const handleOnChange = (event: any, newValue: Subject | null) => {
    if (!newValue) {
      setIsEmpty(true);
      const defaultSubject = {
        label: 'Matematik',
        icon: '📈',
        value: 'math',
        disabled: false,
        translateLabel: 'register.subjectsDk.math',
        translateDisabled: 'register.subjects.comingSoon',
      };
      setSubject(defaultSubject);
      setShowMathInput(true);
      setInput('');
    } else {
      setIsEmpty(false);
      setSubject(newValue);
      setShowMathInput(mathInputSubjects.includes(newValue.value));
    }
  };

  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <Typography variant="caption" sx={{ mr: 1, color: theme.palette.text.secondary }}>
        {String(translate('lessonPlans.subject'))}:
      </Typography>
      <Autocomplete
        disableClearable
        size="small"
        value={subject}
        sx={{
          minWidth: 120,
          width: 225,
          maxWidth: 300,
          '& .MuiAutocomplete-option': {
            fontSize: '0.75rem',
            padding: '4px 8px',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.background.paper, 0.8),
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            borderColor: isEmpty ? theme.palette.error.main : theme.palette.divider,
            fontSize: '0.75rem',
            '&:hover': {
              borderColor: isEmpty ? theme.palette.error.dark : theme.palette.text.primary,
            },
            '&.Mui-focused': {
              boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
              borderColor: isEmpty ? theme.palette.error.main : theme.palette.divider,
            },
          },
        }}
        options={subjects}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        popupIcon={<Iconify icon={'ph:caret-down'} width={16} />}
        onChange={handleOnChange}
        getOptionDisabled={(option) => !!option.soon}
        renderOption={(props, option) => (
          <li {...props} style={{ opacity: option.soon ? 0.5 : 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography noWrap variant="body2" sx={{ fontSize: '0.75rem' }}>
                {String(translate(option.translateLabel))}
              </Typography>
              {option.soon ? (
                <Chip
                  label={String(translate('genericLabels.comingSoon'))}
                  size="small"
                  color="error"
                  sx={{ ml: 1, height: 16, fontSize: '0.5rem' }}
                />
              ) : (
                <Chip
                  label={String(translate('genericLabels.beta'))}
                  size="small"
                  color="secondary"
                  sx={{ ml: 1, height: 16, fontSize: '0.5rem' }}
                />
              )}
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={isEmpty}
            placeholder={String(translate('register.subjectsDk.required'))}
            sx={{
              fontSize: '0.75rem',
              '& .MuiInputBase-root': {
                padding: '2px 4px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isEmpty ? theme.palette.error.main : 'inherit',
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  <Chip
                    label={String(translate('genericLabels.beta'))}
                    size="small"
                    color="secondary"
                    sx={{ mr: 1, height: 16, fontSize: '0.5rem' }}
                  />
                </>
              ),
            }}
          />
        )}
        getOptionLabel={(option) => String(translate(option.translateLabel)) || ''}
      />
    </Box>
  );
}
