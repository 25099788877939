import { useState } from 'react';
// @mui
import { Box, Tooltip, Typography, Link, Stack } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import { fNumber } from '../../../utils/formatNumber';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useLocales } from '../../../locales';
import { AuthUserType } from '../../../auth/types';
import { fDateTime } from 'src/utils/formatTime';
import { ExtraCredits } from 'src/sections/@dashboard/membership';
import { IInvoice } from 'src/@types/invoice';
import PurchaseTokensButton from '../components/PurchaseTokensButton';
import ResetAiTokensButton from '../components/ResetAiTokensButton';

type Props = {
  width?: number;
  height?: number;
  user?: AuthUserType;
  user_credits: number;
  latestCustomerOrder: IInvoice | null;
  latestNonMembershipOrder: IInvoice | null;
};

export default function CustomerCredits({
  user,
  user_credits,
  latestCustomerOrder,
  latestNonMembershipOrder,
}: Props) {
  const { translate } = useLocales();
  const { pathname } = useLocation();

  const hasMembership = user?.customer_dict
    ? user?.customer_dict.has_membership
    : user?.student && user?.student_dict
    ? user?.student_dict.has_membership
    : false;
  const freemiumUser = user?.freemium_signup;

  const aiTokens = user?.student_dict?.ai_tokens || user?.customer_dict?.ai_tokens;
  const hasAiTokens =
    user?.customer_dict?.has_ai_token_order || user?.student_dict?.has_ai_token_order;
  const [openPurchaseTokens, setOpenPurchaseTokens] = useState(false);
  const [openRefreshTokens, setOpenRefreshTokens] = useState(false);

  const renderTooltipContent = (): React.ReactNode => {
    if (hasMembership) {
      return (
        <Box
          p={3}
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Stack direction={'row'}>
              {user?.customer_dict?.has_membership ? (
                <>
                  <Iconify
                    icon="ph:clock-clockwise"
                    width={24}
                    height={24}
                    color="black.main"
                    sx={{ mr: 1 }}
                  />
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    {String(translate('customer.membership.refresh'))}
                    {': '}
                    {fDateTime(
                      user?.expiration_date || user?.customer_dict?.expiration_date,
                      'dd MMM yyyy'
                    )}
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="body2"
                  component="div"
                  color={'error.main'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  {String(translate('overview.ranOutHours'))}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Box mt={2} display="flex" justifyContent="center">
            <ExtraCredits latestCustomerOrder={latestCustomerOrder} navbar={true} />
          </Box>
        </Box>
      );
    } else if (freemiumUser) {
      return <></>;
    } else {
      return (
        <Box
          p={3}
          sx={{
            backgroundColor: 'background.paper', // Use your theme colors
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <Box mt={2} display="flex" justifyContent="center">
            <ExtraCredits latestCustomerOrder={latestNonMembershipOrder} navbar={true} />
          </Box>
        </Box>
      );
    }
  };

  return (
    <Tooltip
      title={renderTooltipContent()}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'transparent', // Removes default background
            boxShadow: 'none', // Removes default shadow
            padding: 0,
          },
          '& .MuiTooltip-arrow': {
            color: 'background.paper', // Arrow color to match the custom content
          },
        },
      }}
    >
      {hasMembership && user?.customer ? (
        <Stack display="flex" alignItems="center" direction={'row'} spacing={2}>
          <Link
            underline="none"
            component={RouterLink}
            to={`/membership/view/${user?.customer_dict?.id}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              gap: 2,
            }}
          >
            {pathname !== '/ai' && (
              <Box display="flex" alignItems="center">
                <Iconify icon="ph:vault" width={20} height={20} color="black.main" />
                <Typography variant="body2" color="black.main" component="span" marginLeft={1}>
                  {`${translate('genericLabels.balance')}`}: {fNumber(user_credits)}{' '}
                  {`${translate('genericLabels.hours')}`}
                </Typography>
              </Box>
            )}

            {user_credits <= 0 && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={1}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Iconify icon="ph:warning" width={20} height={20} color="error.main" />
                <Typography color={'error.main'} variant="body2">
                  {`${translate('genericLabels.noMoreHours')}`}
                </Typography>
              </Stack>
            )}

            <Box display="flex" alignItems="center">
              <Typography
                variant="body2"
                component="div"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
              >
                <Iconify icon="ph:sparkle" width={20} height={20} color="primary" sx={{ mr: 1 }} />
                <Typography variant="body2" color="primary" component="span">
                  {String(translate('customer.ai_tokens'))}
                  {': '}
                  {fNumber(aiTokens)}
                </Typography>
              </Typography>
            </Box>
          </Link>

          {hasAiTokens ? (
            <ResetAiTokensButton dontShowButton={false} />
          ) : (
            <PurchaseTokensButton
              open={openPurchaseTokens}
              setOpen={setOpenPurchaseTokens}
              dontShowButton={false}
            />
          )}
        </Stack>
      ) : (
        <Stack display="flex" alignItems="center" direction={'row'} spacing={2}>
          {!user?.freemium_signup && pathname !== '/ai' && (
            <Box display="flex" alignItems="center">
              <Iconify icon="ph:vault" width={24} height={24} color="black.main" />
              <Typography variant="body1" color="black.main" component="span" marginLeft={1}>
                {`${translate('genericLabels.balance')}`}: {fNumber(user_credits)}{' '}
                {`${translate('genericLabels.hours')}`}
              </Typography>
            </Box>
          )}

          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              component="div"
              color="black.main"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', ml: 1 }}
            >
              <Iconify icon="ph:sparkle" width={24} height={24} color="black.main" sx={{ mr: 1 }} />
              {String(translate('customer.ai_tokens'))}
              {': '}
              {fNumber(aiTokens)}
            </Typography>
            {hasAiTokens ? (
              <ResetAiTokensButton dontShowButton={false} />
            ) : (
              <PurchaseTokensButton
                open={openPurchaseTokens}
                setOpen={setOpenPurchaseTokens}
                dontShowButton={false}
              />
            )}
          </Box>
        </Stack>
      )}
    </Tooltip>
  );
}
