import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import overwriteArray from '../../utils/overwriteArray';

import {
  ICalendarLessonRequest,
  ILessonRequest,
  ILessonRequestState,
} from '../../@types/lessonRequest';
import { createEventSuccess } from './calendar';
const initialState: ILessonRequestState = {
  isLoading: false,
  error: null,
  lessonrequests: { data: [], page: 0, offset: 0, total: 0 },
  teacherLessonRequests: [],
  calendarRequests: [],
  lessonrequest: null,
};

const slice = createSlice({
  name: 'lessonrequests',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null;
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET All LessonRequest
    getLessonRequestsSuccess(state, action) {
      state.isLoading = false;
      state.lessonrequests = {
        data: overwriteArray(state.lessonrequests.data, action.payload.data),
        page: 25 / action.payload.pagination.offset,
        offset: action.payload.pagination.offset,
        total: action.payload.pagination.total,
      };
    },
    getCalendarLessonRequests(state, action) {
      state.isLoading = false;
      state.calendarRequests = action.payload;
    },
    // GET All LessonRequest
    getTeacherLessonRequestsSuccess(state, action) {
      state.isLoading = false;
      state.teacherLessonRequests = action.payload;
    },
    changeLessonRequest(state, action) {
      state.isLoading = false;
      state.teacherLessonRequests = state.teacherLessonRequests.filter(
        (lesson) => lesson.id !== action.payload
      );
    },
    // GET Specific LessonRequest by ID
    getLessonRequestSuccess(state, action) {
      state.isLoading = false;
      state.lessonrequest = action.payload.data;
      state.error = null;
    },
    createLessonRequestSuccess(state, action) {
      state.isLoading = false;
      state.calendarRequests = [action.payload, ...state.calendarRequests];
      state.error = null;
    },
    refundLessonRequestSuccess(state, action) {
      state.isLoading = false;
      state.lessonrequest = action.payload.data;
      state.lessonrequests = {
        ...state.lessonrequests,
        data: [...state.lessonrequests.data, action.payload.data],
      };
      state.error = null;
    },
    updateLessonRequestSuccess(state, action) {
      state.isLoading = false;
      state.lessonrequest = action.payload;
      state.error = null;
    },
    deleteLessonRequestSuccess(state, action) {
      state.isLoading = false;
      state.lessonrequests = {
        ...state.lessonrequests,
        data: state.lessonrequests.data.filter((request) => request.id !== action.payload),
      };
      state.calendarRequests = state.calendarRequests.filter(
        (request) => request.id !== action.payload
      );
    },
  },
});

export default slice.reducer;

export function getTeacherLessonRequests(id: number | string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/lesson_requests_teacher/${id}`, {
        withCredentials: true,
      });
      // console.log(response);
      dispatch(slice.actions.getTeacherLessonRequestsSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getLessonRequest(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/lessonrequest/${id}`, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Order not found');
      } else {
        dispatch(slice.actions.getLessonRequestSuccess(response));
        // console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createLessonRequest(newEvent: ICalendarLessonRequest) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const objectForRequest = {
      teacher_id: parseInt(newEvent.teacherId.code),
      student_id: parseInt(newEvent.studentId.code),
      from_time: newEvent.start,
      to_time: newEvent.end,
      trial_lesson: newEvent.trial_lesson,
      recurring: newEvent.recurring,
    };
    try {
      const response = await axios.post(
        `/api/make_request`,
        { ...objectForRequest },
        {
          withCredentials: true,
        }
      );
      // console.log(response);
      if (response.status === 404) {
        throw new Error('Teacher not found');
      } else {
        dispatch(
          slice.actions.createLessonRequestSuccess({
            title: response.data.title,
            id: response.data.id,
            start: new Date(`${response.data.from_time.toString()}Z`),
            end: new Date(`${response.data.to_time.toString()}Z`),
            description: response.data.description,
            paid: false,
            request: true,
            studentName: `${response.data.studentName}`,
            teacherName: `${response.data.teacherName}`,
            completionNotes: response.data.completion_notes,
            status: response.data.status,
            trial_lesson: response.data.trial_lesson,
            recurring: response.data.recurring,
            teacherId: {
              code: response.data.teacherId.code,
              label: `${response.data.teacherName} `,
            },
            studentId: {
              code: response.data.studentId.code,
              label: `${response.data.studentName}`,
            },
            color: '#FAE993',
          })
        );
        // console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function acceptLessonRequest(
  lessonrequest: ILessonRequest,
  title: string,
  description: string,
  teacher_id: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const objectForRequest = {
      request_id: lessonrequest.id,
      title: title,
      description: description,
      teacher_id: teacher_id,
    };
    try {
      const response = await axios.post(
        `/api/approve_request`,
        { ...objectForRequest },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Error: Does not exist');
      } else {
        dispatch(slice.actions.changeLessonRequest(lessonrequest.id));
        dispatch(
          createEventSuccess({
            title: response.data.title,
            id: response.data.id,
            start: new Date(`${response.data.from_time.toString()}Z`),
            end: new Date(`${response.data.to_time.toString()}Z`),
            description: response.data.description,
            paid: false,
            studentName: `${response.data.student.first_name} ${response.data.student.last_name}`,
            teacherName: `${response.data.teacher.first_name} ${response.data.teacher.last_name}`,
            completionNotes: response.data.completion_notes,
            status: response.data.status,
            trial_lesson: response.data.trial_lesson,
            recurring: response.data.recurring,
            request: true,
            teacherId: {
              code: response.data.teacher.id,
              label: `${response.data.teacher.first_name} ${response.data.teacher.last_name}`,
            },
            studentId: {
              code: response.data.student.id,
              label: `${response.data.student.first_name} ${response.data.student.last_name}`,
            },
            color: '#FFD400',
          })
        );
        return true;
      }
    } catch (error) {
      console.log(error);

      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function rejectLessonRequest(lessonrequest: ILessonRequest, rejection_reason: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const objectForRequest = { request_id: lessonrequest.id, rejection_reason: rejection_reason };
    try {
      const response = await axios.post(
        `/api/reject_request`,
        { ...objectForRequest },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Order not found');
      } else {
        dispatch(slice.actions.changeLessonRequest(response));
        // console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function cancelLessonRequest(
  lessonrequest_id: number | string,
  cancellation_reason: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const objectForRequest = { request_id: lessonrequest_id, cancellation_reason };
    try {
      const response = await axios.post(
        `/api/cancel_request`,
        { ...objectForRequest },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Request not found');
      } else {
        dispatch(
          slice.actions.updateLessonRequestSuccess({
            ...response.data,
            status: response.data.rejection_reason.startsWith('CANCELLED BY STUDENT:')
              ? 'cancelled'
              : response.data.status,
          })
        );
        dispatch(slice.actions.deleteLessonRequestSuccess(parseInt(response.data.id, 10)));
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

/**
 * Fetches events for customers with the given customerId.
 *
 * @param {number} customerId - The ID of the customer.
 * @returns {Function} A Redux Thunk action creator.
 */
export function getRequestsCustomers(
  customerId: number,
  from_date: string | undefined,
  to_date: string | undefined
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/requests-customer/${customerId}?start_date=${from_date}&end_date=${to_date}`,
        {
          withCredentials: true,
        }
      );
      const eventsForReturn = response.data.data.map((request: ICalendarLessonRequest) => {
        return {
          ...request,
          title: request.title,
          id: request.id,
          start: new Date(`${request.from_time.toString()}Z`),
          end: new Date(`${request.to_time.toString()}Z`),
          color: request.color,
          trial_lesson: request.trial_lesson,
          paid: request.paid,
          studentName: request.studentName,
          teacherName: request.teacherName,
          studentId: request.studentId,
          teacherId: request.teacherId,
          status: request.status,
          request: true,
        };
      });
      dispatch(slice.actions.getCalendarLessonRequests(eventsForReturn));
      return true; // Return true on success
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

/**
 * Fetches events for customers with the given customerId.
 *
 * @param {number} customerId - The ID of the customer.
 * @returns {Function} A Redux Thunk action creator.
 */
export function getRequestsStudents(
  customerId: number,
  from_date: string | undefined,
  to_date: string | undefined
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/requests-student/${customerId}?start_date=${from_date}&end_date=${to_date}`,
        {
          withCredentials: true,
        }
      );
      // console.log(response);
      const eventsForReturn = response.data.data.map((request: ICalendarLessonRequest) => {
        return {
          ...request,
          title: request.title,
          id: request.id,
          start: new Date(`${request.from_time.toString()}Z`),
          end: new Date(`${request.to_time.toString()}Z`),
          color: request.color,
          trial_lesson: request.trial_lesson,
          paid: request.paid,
          studentName: request.studentName,
          teacherName: request.teacherName,
          studentId: request.studentId,
          teacherId: request.teacherId,
          status: request.status,
          request: true,
        };
      });
      dispatch(slice.actions.getCalendarLessonRequests(eventsForReturn));
      return true; // Return true on success
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
