import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

import { ILeaderboardState } from '../../@types/leaderboard';

const initialState: ILeaderboardState = {
  isLoading: false,
  error: null,
  leaderboard: [],
};

const slice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; // if you only write action.payload, you do not dot-in to the actual data, where all data for leaderboard is
    },
    // GET Leaderboard
    getLeaderboardSuccess(state, action) {
      state.isLoading = false;
      state.leaderboard = action.payload;
    },
  },
});

export default slice.reducer;

export function getLeaderboards() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/get_leaderboard`);
      dispatch(slice.actions.getLeaderboardSuccess(response.data));
      return true;
      // console.log(response.data);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
