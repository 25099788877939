import { Theme } from '@mui/material/styles';
import { TFunction } from 'i18next';

export const subjects_dk = [
  {
    label: 'Matematik',
    icon: '📈',
    value: 'math',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.math',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Dansk',
    icon: '🇩🇰',
    value: 'danish',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.danish',
    translateDisabled: 'register.subjects.comingSoon',
    market: '1',
  },
  {
    label: 'Engelsk',
    icon: '🇬🇧',
    value: 'english',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.english',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Biologi',
    icon: '🧬',
    value: 'biology',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.biology',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Fysik/kemi',
    icon: '🧪',
    value: 'physics/chemistry',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.chemistry',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Afsætning',
    icon: '🇩🇰',
    value: 'afsætning',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.afsætning',
    translateDisabled: 'register.subjects.comingSoon',
    market: '1',
  },
  {
    label: 'Virksomhedsøkonomi',
    icon: '🇩🇰',
    value: 'virksomhedsøkonomi',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.virksomhedsøkonomi',
    translateDisabled: 'register.subjects.comingSoon',
    market: '1',
  },
  {
    label: 'Bioteknologi',
    icon: '🇩🇰',
    value: 'bioteknologi',
    disabled: false,
    ai_tutor: true,
    translateLabel: 'register.subjectsDk.bioteknologi',
    translateDisabled: 'register.subjects.comingSoon',
    market: '1',
  },
  {
    label: 'Tysk',
    icon: '🇩🇪',
    value: 'german',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.german',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Spansk',
    icon: '🇪🇸',
    value: 'spanish',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.spanish',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Fransk',
    icon: '🇫🇷',
    value: 'french',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.french',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Samfundsfag',
    icon: '⚖️',
    value: 'socialstudies',
    disabled: false,
    ai_tutor: true,
    soon: false,
    translateLabel: 'register.subjectsDk.socialStudies',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Historie',
    icon: '📚',
    value: 'history',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.history',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Geografi',
    icon: '🌏',
    value: 'geography',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.geography',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Natur/teknik',
    icon: '🐢',
    value: 'nature/technologi',
    disabled: false,
    ai_tutor: true,
    soon: true,
    translateLabel: 'register.subjectsDk.natureAndTechnology',
    translateDisabled: 'register.subjects.comingSoon',
  },
  {
    label: 'Galician',
    icon: 'circle-flags:es-ga',
    value: 'galician',
    disabled: false,
    ai_tutor: false,
    translateLabel: 'register.subjectsDk.galician',
    translateDisabled: 'register.subjects.comingSoon',
    market: '2',
  },
  {
    label: 'Galician',
    icon: '',
    value: 'gallician',
    disabled: false,
    ai_tutor: false,
    translateLabel: 'register.subjectsDk.galician',
    translateDisabled: 'register.subjects.comingSoon',
    market: '2',
  },
  {
    label: 'Religion',
    icon: '📖',
    value: 'religion',
    disabled: false,
    ai_tutor: false,
    translateLabel: 'register.subjectsDk.religion',
    translateDisabled: 'register.subjects.comingSoon',
    market: '2',
  },
  {
    label: 'Catalan',
    icon: '',
    value: 'catalan',
    disabled: false,
    ai_tutor: false,
    translateLabel: 'register.subjectsDk.catalan',
    translateDisabled: 'register.subjects.comingSoon',
    market: '2',
  },
  {
    label: 'Economics',
    icon: '📊',
    value: 'economics',
    disabled: false,
    ai_tutor: false,
    translateLabel: 'register.subjectsDk.economics',
    translateDisabled: 'register.subjects.comingSoon',
    market: '2',
  },
  {
    label: 'Philosophy',
    icon: '🤔',
    value: 'philosophy',
    disabled: false,
    ai_tutor: false,
    translateLabel: 'register.subjectsDk.philosophy',
    translateDisabled: 'register.subjects.comingSoon',
    market: '2',
  },
  {
    label: 'Informatica',
    icon: '💻',
    value: 'informatica',
    disabled: false,
    ai_tutor: false,
    translateLabel: 'register.subjectsDk.informatica',
    translateDisabled: 'register.subjects.comingSoon',
    market: '2',
  },

  // {
  //   label: 'Erhvervsjura',
  //   icon: '🇩🇰',
  //   value: 'erhvervsjura',
  //   disabled: false,
  //   ai_tutor: true,
  //   translateLabel: 'register.subjectsDk.erhvervsjura',
  //   translateDisabled: 'register.subjects.comingSoon',
  // },
  // {
  //   label: 'Psykologi',
  //   icon: '🇩🇰',
  //   value: 'psykologi',
  //   disabled: false,
  //   ai_tutor: true,
  //   translateLabel: 'register.subjectsDk.psykologi',
  //   translateDisabled: 'register.subjects.comingSoon',
  // },
];

export const interests_dk = [
  { label: 'Football', icon: '⚽️' },
  { label: 'Swimming', icon: '🏊' },
  { label: 'Gaming', icon: '🎮' },
  { label: 'Reading', icon: '📚' },
  { label: 'Boxing', icon: '🥊' },
  { label: 'Painting', icon: '🎨' },
  { label: 'Movies', icon: '🎬' },
  { label: 'Skating', icon: '🛹' },
  { label: 'Handball', icon: '🤾' },
  { label: 'Photography', icon: '📸' },
  { label: 'Basketball', icon: '🏀' },
  { label: 'Music', icon: '🎧' },
  { label: 'Ice Hockey', icon: '🏒' },
  { label: 'Tennis', icon: '🎾' },
  { label: 'Biking', icon: '🚴' },
  { label: 'Table Tennis', icon: '🏓' },
  { label: 'Skiing', icon: '⛷' },
  { label: 'Badminton', icon: '🏸' },
  { label: 'Golf', icon: '⛳️' },
  { label: 'Horse Riding', icon: '🏇' },
  { label: 'Bowling', icon: '🎳' },
  { label: 'Running', icon: '👟' },
  { label: 'Crochet', icon: '🧶' },
  { label: 'Drawing', icon: '✍️' },
  { label: 'Cooking', icon: '🍲' },
  { label: 'Hiking', icon: '🏔' },
  { label: 'Fishing', icon: '🎣' },
  { label: 'Sewing', icon: '🪡' },
  { label: 'Karate', icon: '🥋' },
  { label: 'Puzzles', icon: '🧩' },
];
