import { useState } from 'react';
import { Box, CardContent, Grid } from '@mui/material';

import { Card, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import { CloseIcon } from 'yet-another-react-lightbox/core';
import SurveyEmoji from './SurveyEmoji';

const RATINGS = [1, 2, 3, 4, 5];

export default function SurveyRating({ selectedSurvey, dismissSurvey }: any) {
  const { translate } = useLocales();
  const [selectedRating, setSelectedRating] = useState(0);

  const handleSurveyDismiss = () => {
    dismissSurvey();
  };

  return (
    <Card style={{ borderRadius: 0, padding: '1px', width: '400px' }}>
      <Grid sx={{ p: 2 }} container>
        <Grid item xs={11}>
          <Typography>{`${translate('survey.' + selectedSurvey.survey_message)}`}</Typography>
        </Grid>
        <Grid sx={{ alignment: 'flex-end' }} item xs={1}>
          <CloseIcon color="error" style={{ cursor: 'pointer' }} onClick={handleSurveyDismiss} />
        </Grid>

        <Typography mt={2} fontSize={13}>
          {/* {`${translate('survey.' + selectedSurvey.description)}`} */}
        </Typography>
      </Grid>
      <CardContent>
        <Grid
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
          container
        >
          {RATINGS.map((r, indx) => (
            <Grid key={r.toString()} item>
              <Box
              // onClick={() => {
              //   setSelectedRating(r);
              // }}
              // variant={selectedRating === r ? 'contained' : 'outlined'}
              >
                <SurveyEmoji
                  optionIndx={indx}
                  survey
                  selected={selectedRating === r}
                  optionName={r.toString()}
                />
              </Box>
            </Grid>
          ))}
          <Grid item></Grid>
        </Grid>
        <Grid
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
          }}
          container
        >
          <Grid item>
            <Typography fontSize={11} color="grey">
              {`${translate('survey.dissatisfied')}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={11} color="grey">
              {`${translate('survey.very_satisfied')}`}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: '10px' }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            {/* <Button
              disabled={selectedRating === 0}
              onClick={handleSurveySubmit}
              variant="contained"
              sx={{ borderRadius: 1 }}
            >
              {`${translate('survey.submit')}`}
            </Button> */}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}
