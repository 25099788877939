// react
import { useEffect, useState, useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  FormHelperText,
  Dialog,
  IconButton,
  Button,
  Box,
  Stack,
  Typography,
  StackProps,
  Tooltip,
  useTheme,
  SxProps,
} from '@mui/material';
// @mui
import { styled, alpha, Theme } from '@mui/material/styles';
import { m, AnimatePresence } from 'framer-motion';
import { varFade } from '../../../../components/animate';

// components
import Cropper, { ReactCropperElement } from 'react-cropper';
import Iconify from 'src/components/iconify';
import RejectionFiles from '../../../../components/upload/errors/RejectionFiles';
import { UploadProps } from '../../ai_helper';
import FileThumbnail from '../../../../components/file-thumbnail-kopi';
// hooks
import { useLocales } from 'src/locales';
import { useDropzone } from 'react-dropzone';
import useResponsive from 'src/hooks/useResponsive';
// css
import 'cropperjs/dist/cropper.css';

// Styled component for DropZone
const StyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.common.white,
  width: 'calc(100%)',
  height: 'calc(100%)',
  border: `0px dashed ${alpha(theme.palette.common.black, 0.5)}`,
  '&:hover': {
    opacity: 0.72,
  },
}));

// Placeholder Component
const Placeholder = ({ sx, ...other }: StackProps) => {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Stack
      spacing={5}
      alignItems="center"
      justifyContent="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        width: 1,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        height: { xs: 200, sm: 240, md: 280, lg: 320 },
        borderRadius: theme.shape.borderRadius,
        ...sx,
      }}
      {...other}
    >
      <div>
        <Typography gutterBottom variant="h5">
          {String(translate('ai.file.dropOrSelect'))}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {String(translate('ai.file.dropHere'))}
          <Typography
            variant="body2"
            component="span"
            sx={{
              mx: 0.5,
              color: 'primary.main',
              textDecoration: 'underline',
            }}
          >
            {String(translate('ai.file.browse'))}
          </Typography>
          {String(translate('ai.file.throughMachine'))}
        </Typography>
      </div>
    </Stack>
  );
};
//
import Image from '../../../../components/image';
//
import { CustomFile } from '../../../../components/upload/types';

// ----------------------------------------------------------------------

type PropsFilePreview = {
  file: CustomFile | string | null;
  sx?: SxProps<Theme>;
};
function SingleFilePreview({ file, sx }: PropsFilePreview) {
  if (!file) {
    return null;
  }
  console.log(file);
  const imgUrl = typeof file === 'string' ? file : file.preview;
  const isPdf = typeof file !== 'string' && file?.type === 'application/pdf';
  console.log(isPdf);

  return isPdf ? (
    <div
      style={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    >
      <AnimatePresence initial={false}>
        <Stack
          component={m.div}
          {...varFade().inUp}
          alignItems="center"
          display="inline-flex"
          justifyContent="center"
          sx={{
            m: 0.5,
            width: 80,
            height: 80,
            borderRadius: 1.25,
            overflow: 'hidden',
            position: 'relative',
            border: (theme) => `solid 1px ${theme.palette.divider}`,
            ...sx,
          }}
        >
          <FileThumbnail file={file} />
        </Stack>
      </AnimatePresence>
    </div>
  ) : (
    <Image
      alt="file preview"
      src={imgUrl}
      objectFit={'contain'}
      ratio="16/9"
      sx={{
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
        minHeight: '400px',
        objectFit: 'contain', // Use 'contain' to maintain the aspect ratio and fill the container
        objectPosition: 'center', // Center the image within the box
      }}
    />
  );
}

// UploadWithCrop Component
const UploadWithCrop = ({
  disabled,
  getFileValue,
  multiple = false,
  error,
  helperText,
  file,
  onDelete,
  ...other
}: UploadProps) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    ...other,
  });
  const hasFile = !!file && !multiple;

  useEffect(() => {
    if (hasFile && getFileValue) {
      getFileValue(file);
    }
  }, [file, getFileValue, hasFile]);

  const isError = isDragReject || !!error;

  return (
    <Box sx={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)' }}>
      {!hasFile && (
        <StyledDropZone
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...(isError && {
              color: 'error.contrastText',
              bgcolor: 'error.main',
              borderColor: 'error.main',
            }),
            ...(disabled && { opacity: 0.48, pointerEvents: 'none' }),
            // ...(hasFile && { padding: '25% 0' }),
          }}
        >
          <input {...getInputProps()} />
          <Placeholder />
        </StyledDropZone>
      )}

      {hasFile && <SingleFilePreview file={file} />}

      <RejectionFiles fileRejections={[...fileRejections]} helperText={helperText} />

      {hasFile && onDelete && (
        <IconButton
          size="small"
          onClick={onDelete}
          sx={{
            top: 16,
            right: 16,
            zIndex: 9,
            position: 'absolute',
            color: (theme) => alpha(theme.palette.common.white, 0.8),
            bgcolor: (theme) => alpha(theme.palette.common.black, 0.72),
            '&:hover': { bgcolor: (theme) => alpha(theme.palette.common.black, 0.48) },
          }}
        >
          <Iconify icon="eva:close-fill" width={18} />
        </IconButton>
      )}
    </Box>
  );
};

// Main Component
interface Props extends Omit<UploadProps, 'file'> {
  name: string;
  multiple?: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
  uploadFile: () => void;
  handleCrop: (fileData: CustomFile) => void;
  onDelete?: VoidFunction;
}

const AIUpload = ({
  name,
  multiple,
  open,
  setOpen,
  uploadFile,
  handleCrop,
  helperText,
  onDelete,
  ...other
}: Props) => {
  const { control } = useFormContext();
  const { translate } = useLocales();
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');

  const [fileValue, setFileValue] = useState<string | CustomFile>('');
  const [crop, setCrop] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    // Perform crop logic here if needed
  };

  const handleDeleteFile = () => {
    setFileValue('');
    setCrop(false);
    onDelete && onDelete();
  };

  const dataURLToBlob = (dataURL: string) => {
    const [header, base64] = dataURL.split(',');
    const mime = (header.match(/:(.*?);/)?.[1] ?? 'application/octet-stream') as string;
    const binary = atob(base64);
    const array = Array.from(binary, (char) => char.charCodeAt(0));
    return new Blob([new Uint8Array(array)], { type: mime });
  };

  const finishCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedDataURL = cropper.getCroppedCanvas().toDataURL();
      setFileValue(croppedDataURL);
      setCrop(false);
      setOpen(false);

      const blob = dataURLToBlob(croppedDataURL);
      const fileName = 'croppedImage.png';
      const fileData = new File([blob], fileName, { type: blob.type, lastModified: Date.now() });
      handleCrop(fileData);
    }
  };

  return (
    <>
      <Tooltip title={String(translate('ai.file.tooltip'))}>
        <Button
          sx={{
            minWidth: 0,
            p: 0,
            flexShrink: 0,
            border: '0px solid',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: alpha(theme.palette.groundingGrey.main, 0.0),
            '&:hover': {
              backgroundColor: alpha(theme.palette.groundingGrey.main, 0),
              boxShadow: 'none',
            },
          }}
          variant="contained"
          color="info"
          onClick={() => setOpen(true)}
        >
          <m.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
          >
            <Iconify
              color="black"
              sx={{ width: 24, height: 24, transform: 'rotate(90deg)' }}
              icon="ph:paperclip-horizontal"
            />
          </m.div>
        </Button>
      </Tooltip>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ borderRadius: theme.palette.borders.borderRadius }}
      >
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) =>
            !crop ? (
              <UploadWithCrop
                crop={crop}
                multiple={false}
                getFileValue={setFileValue}
                sx={{ width: 'calc(100% - 16px)', height: 'calc(100% - 16px)' }}
                accept={{ 'image/*': ['.jpeg', '.jpg', '.png'], 'application/pdf': ['.pdf'] }}
                file={field.value}
                error={!!error}
                onDelete={handleDeleteFile}
                helperText={
                  (!!error || helperText) && (
                    <FormHelperText error={!!error} sx={{ px: 2 }}>
                      {error ? error.message : helperText}
                    </FormHelperText>
                  )
                }
                {...other}
              />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <IconButton
                  size="small"
                  onClick={handleDeleteFile}
                  sx={{
                    top: 16,
                    right: 16,
                    zIndex: 9,
                    position: 'absolute',
                    color: (theme) => alpha(theme.palette.common.white, 0.8),
                    bgcolor: (theme) => alpha(theme.palette.common.black, 0.72),
                    '&:hover': { bgcolor: (theme) => alpha(theme.palette.common.black, 0.48) },
                  }}
                >
                  <Iconify icon="eva:close-fill" width={18} />
                </IconButton>
                <Cropper
                  src={typeof fileValue === 'string' ? fileValue : fileValue.preview}
                  style={{
                    width: 'calc(100% - 16px)',
                    height: 'calc(100% - 16px)',
                    minHeight: '400px',
                    objectFit: 'contain', // Use 'contain' to maintain the aspect ratio and fill the container
                    objectPosition: 'center', // Center the image within the box
                  }}
                  initialAspectRatio={16 / 9}
                  guides={false}
                  crop={onCrop}
                  viewMode={1}
                  ref={cropperRef}
                />
              </Box>
            )
          }
        />

        {fileValue && typeof fileValue !== 'string' && fileValue.type !== 'application/pdf' && (
          <Stack direction="row" spacing={2} sx={{ width: 'fit-content', mx: 'auto', zIndex: 999 }}>
            {/* Guidance Box for taking a photo */}
            <Box
              sx={{
                position: 'absolute',
                minWidth: 'fit-content', // Ensure the box can fit its content
                borderRadius: theme.palette.borders.borderRadius,
                top: 20, // Position so the guidance box is just above the main box
                left: '50%',
                transform: 'translateX(-50%)', // Center horizontally
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'rgba(51, 60, 75, 0.33)', // Using transparent black for darker blur
                backdropFilter: 'blur(10px)', // blur effect
                padding: '12px', // Padding around the text
              }}
            >
              <Typography
                variant="body2" // Smaller text variant
                color="background.paper" // Ensuring the text is visible on the darker background
                textAlign="center"
              >
                {`${translate('ai.file.cropTip')}`}
              </Typography>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                width: 'fit-content',
                borderRadius: '25px',
                bottom: 10, // Adjust this value to move the box higher or lower
                left: '50%',
                transform: 'translateX(-50%)', // Center horizontally
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'fit-content', // Adjust based on your needs
                bgcolor: 'rgba(51, 60, 75, 0.33)', // Using transparent black for darker blur
                gap: '40px',
                padding: '20px',
              }}
            >
              {!crop ? (
                <>
                  <IconButton
                    color="info"
                    onClick={() => setCrop(true)}
                    sx={{
                      // bgcolor: 'background.paper', // A neutral background to start
                      ':hover': {
                        transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                      },
                      background: 'white',
                      transition: 'all 0.3s ease', // Smooth transition for the hover effects
                      borderRadius: '50%', // Keeps the circular shape
                      border: '2px solid', // Add a border for definition
                      borderColor: 'info.main', // Use the theme's error color for the border
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                      '&:active': {
                        transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                      },
                    }}
                  >
                    <Iconify sx={{ width: 25, height: 25 }} icon="ph:crop"></Iconify>
                  </IconButton>

                  <IconButton
                    color="primary"
                    onClick={() => {
                      uploadFile();
                      setOpen(false);
                    }}
                    sx={{
                      bgcolor: 'secondary.main', // A neutral background to start
                      ':hover': {
                        bgcolor: 'secondary.main', // A neutral background to start
                        transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                      },
                      transition: 'all 0.3s ease', // Smooth transition for the hover effects
                      borderRadius: '50%', // Keeps the circular shape
                      border: '2px solid', // Add a border for definition
                      borderColor: 'primary.main', // Use the theme's error color for the border
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                      '&:active': {
                        transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                      },
                    }}
                  >
                    <Iconify
                      sx={{ width: 25, height: 25, transform: 'rotate(130deg)' }}
                      icon="ph:navigation-arrow"
                    />
                  </IconButton>
                </>
              ) : (
                // <Button
                //   variant="contained"
                //   onClick={finishCrop}
                //   startIcon={<Iconify icon="ph:check-circle" />}
                //   sx={{ minWidth: '200px' }}
                //   color="success"
                //   size="large"
                // >
                //   {String(translate('ai.file.finishCrop'))}
                // </Button>
                <IconButton
                  color="primary"
                  onClick={finishCrop}
                  sx={{
                    bgcolor: 'secondary.main', // A neutral background to start
                    ':hover': {
                      bgcolor: 'secondary.main', // A neutral background to start
                      transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                    },
                    transition: 'all 0.3s ease', // Smooth transition for the hover effects
                    borderRadius: '50%', // Keeps the circular shape
                    border: '2px solid', // Add a border for definition
                    borderColor: 'primary.main', // Use the theme's error color for the border
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                    '&:active': {
                      transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                    },
                  }}
                >
                  <Iconify sx={{ width: 25, height: 25 }} icon="ph:check"></Iconify>
                </IconButton>
              )}
            </Box>
          </Stack>
        )}
      </Dialog>
    </>
  );
};

export default AIUpload;
