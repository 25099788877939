import React, { useState, useRef } from 'react';
import {
  Button,
  Dialog,
  Typography,
  Tooltip,
  IconButton,
  Box,
  Stack,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import { m } from 'framer-motion';
// Camera utils
import Camera from 'react-html5-camera-photo';
import Webcam from 'react-webcam';
import Cropper, { ReactCropperElement } from 'react-cropper';
import Image from '../../../../components/image';
import { FACING_MODES } from 'react-html5-camera-photo';
import { uploadScreenshot } from 'src/redux/slices/ai';
// hooks
import { useDispatch } from '../../../../redux/store';
import { useAuthContext } from 'src/auth/useAuthContext';
import { isMobile } from 'react-device-detect';
import { useLocales } from '../../../../locales';
// components
import Iconify from '../../../../components/iconify';
// css
import 'cropperjs/dist/cropper.css';
import 'react-html5-camera-photo/build/css/index.css';

type Props = {
  chat_id: null | number;
  user_id: null | number;
  cookieUses?: number;
  lowerCookies?: () => void;
  goChat: (value: boolean) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  setOpenGallery: (value: boolean) => void;
  grade: string;
  level: string;
  showCamera: boolean;
};

export default function AiCamera({
  chat_id,
  user_id,
  lowerCookies,
  cookieUses,
  open,
  setOpen,
  setOpenGallery,
  goChat,
  grade,
  level,
  showCamera,
}: Props) {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { user, refreshUser } = useAuthContext();

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
  };

  const cropperRef = useRef<ReactCropperElement>(null);
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
  };
  const [crop, setCrop] = useState(false);

  const [screenshot64String, setScreenshot64String] = useState('');

  const webcamRef = React.useRef<Webcam>(null);

  // Capture photo from webcam
  const capture = () => {
    if (webcamRef) {
      if (webcamRef.current) {
        // console.log('capturing');
        const imageSrc = webcamRef.current.getScreenshot();

        const canvas = webcamRef.current.getCanvas();
        if (canvas) {
          setCropperData(canvas);
        }
        if (imageSrc) {
          handleTakePhoto(imageSrc);
        }
      }
    }
  };

  // Set cropper data from canvas
  const setCropperData = (canvas: HTMLCanvasElement) => {
    const cropper = cropperRef.current?.cropper;

    if (cropper && canvas) {
      // You might want to set canvas data like this
      cropper.replace(canvas.toDataURL());
      // setScreenshot64String(canvas.toDataURL());
    }
  };

  // Finalize cropping
  const finishCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      // console.log('cropping');
      const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
      setScreenshot64String(croppedImageDataUrl);
      setCrop(false);
    }
  };
  const theme = useTheme();

  // Upload the captured image
  const handleUploadDesktop = async () => {
    try {
      goChat(true);
      finishCrop();
      if (lowerCookies) {
        lowerCookies();
      }
      setOpen(false);

      if ((cookieUses && cookieUses > 0) || !cookieUses) {
        if (screenshot64String) {
          const response = true;

          await dispatch(
            uploadScreenshot(
              screenshot64String,
              user_id,
              chat_id,
              user?.language,
              grade,
              level,
              cookieUses?.toString() || '0'
            )
          );
          // console.log(response);
          if (response) {
            setScreenshot64String('');
            refreshUser();
          } else {
            throw new Error('Error uploading screenshot');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle photo taken from camera
  const handleTakePhoto = (dataUri: string) => {
    setScreenshot64String(dataUri);
  };
  const handleDeleteFile = () => {
    setScreenshot64String('');
    setCrop(false);
    setOpen(false);
  };
  return (
    <>
      {showCamera && (
        <Tooltip title={`${translate('ai.camera.tooltip')}`}>
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              setOpen(!open);
            }}
            sx={{
              minWidth: 0,
              flexShrink: 0,
              p: 0,
              border: '0px solid',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: (theme) => alpha(theme.palette.groundingGrey.main, 0.0),
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.groundingGrey.main, 0),
                boxShadow: 'none',
              },
            }}
          >
            <m.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            >
              <Iconify color="black" icon="ph:camera" sx={{ width: 24, height: 24 }} />
            </m.div>
          </Button>
        </Tooltip>
      )}
      {open && (
        <>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            {isMobile ? (
              <div>
                {screenshot64String != '' ? (
                  <>
                    <Stack direction="column" spacing={1}>
                      <Box sx={{ position: 'relative' }}>
                        <IconButton
                          size="small"
                          onClick={handleDeleteFile}
                          sx={{
                            top: 16,
                            right: 16,
                            zIndex: 9,
                            position: 'absolute',
                            color: (theme) => alpha(theme.palette.common.white, 0.8),
                            bgcolor: (theme) => alpha(theme.palette.common.black, 0.72),
                            '&:hover': {
                              bgcolor: (theme) => alpha(theme.palette.common.black, 0.48),
                            },
                          }}
                        >
                          <Iconify icon="eva:close-fill" width={18} />
                        </IconButton>
                        <Box
                          sx={{
                            position: 'absolute',
                            minWidth: 'fit-content', // Ensure the box can fit its content
                            borderRadius: theme.palette.borders.borderRadius,
                            top: 20, // Position so the guidance box is just above the main box
                            left: '50%',
                            transform: 'translateX(-50%)', // Center horizontally
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bgcolor: 'rgba(51, 60, 75, 0.33)', // Using transparent black for darker blur
                            backdropFilter: 'blur(10px)', // blur effect
                            padding: '12px', // Padding around the text
                          }}
                        >
                          <Typography
                            variant="body2" // Smaller text variant
                            color="background.paper" // Ensuring the text is visible on the darker background
                            textAlign="center"
                          >
                            {`${translate('ai.file.cropTip')}`}
                          </Typography>
                        </Box>
                        {!crop ? (
                          <Image
                            alt="file preview"
                            ratio="16/9"
                            objectFit="contain"
                            src={screenshot64String}
                            sx={{
                              objectFit: 'contain', // Use 'contain' to maintain the aspect ratio and fill the container
                              objectPosition: 'center', // Center the image within the box
                              width: 'calc(100% - 16px)',
                              height: 'calc(100% - 16px)',
                              minHeight: '400px',
                            }}
                          />
                        ) : (
                          <Cropper
                            src={screenshot64String}
                            style={{
                              width: 'calc(100% - 16px)',
                              height: 'calc(100% - 16px)',
                              minHeight: '400px',
                              objectFit: 'contain', // Use 'contain' to maintain the aspect ratio and fill the container
                              objectPosition: 'center', // Center the image within the box
                            }}
                            // Cropper.js options
                            initialAspectRatio={16 / 9}
                            guides={false}
                            crop={onCrop}
                            ref={cropperRef}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          position: 'absolute',
                          minWidth: 'fit-content', // Ensure the box can fit its content
                          borderRadius: theme.palette.borders.borderRadius,
                          top: 20, // Position so the guidance box is just above the main box
                          left: '50%',
                          transform: 'translateX(-50%)', // Center horizontally
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: 'rgba(51, 60, 75, 0.33)', // Using transparent black for darker blur
                          backdropFilter: 'blur(10px)', // blur effect
                          padding: '12px', // Padding around the text
                        }}
                      >
                        <Typography
                          variant="body2" // Smaller text variant
                          color="background.paper" // Ensuring the text is visible on the darker background
                          textAlign="center"
                        >
                          {`${translate('ai.file.cropTip')}`}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: 'absolute',
                          width: 'fit-content',
                          borderRadius: '25px',
                          bottom: 20, // Adjust this value to move the box higher or lower
                          left: '50%',
                          transform: 'translateX(-50%)', // Center horizontally
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100px', // Adjust based on your needs
                          bgcolor: 'rgba(51, 60, 75, 0.33)', // Using transparent black for darker blur
                          backdropFilter: 'blur(10px)', // Apply blur effect
                          gap: '40px',
                        }}
                      >
                        {' '}
                        {!crop ? (
                          <>
                            {' '}
                            <IconButton
                              color="info"
                              onClick={() => setCrop(true)}
                              sx={{
                                // bgcolor: 'background.paper', // A neutral background to start
                                ':hover': {
                                  transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                                },
                                background: 'white',

                                transition: 'all 0.3s ease', // Smooth transition for the hover effects
                                borderRadius: '50%', // Keeps the circular shape
                                border: '2px solid', // Add a border for definition
                                borderColor: 'info.main', // Use the theme's error color for the border
                                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                                '&:active': {
                                  transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                                  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                                },
                              }}
                            >
                              <Iconify sx={{ width: 25, height: 25 }} icon="ph:crop"></Iconify>
                            </IconButton>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                handleUploadDesktop();
                              }}
                              sx={{
                                bgcolor: 'secondary.main', // A neutral background to start
                                ':hover': {
                                  bgcolor: 'secondary.main', // A neutral background to start
                                  transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                                },
                                transition: 'all 0.3s ease', // Smooth transition for the hover effects
                                borderRadius: '50%', // Keeps the circular shape
                                border: '2px solid', // Add a border for definition
                                borderColor: 'primary.main', // Use the theme's error color for the border
                                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                                '&:active': {
                                  transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                                  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                                },
                              }}
                            >
                              <Iconify
                                sx={{ width: 25, height: 25, transform: 'rotate(130deg)' }}
                                icon="ph:navigation-arrow"
                              ></Iconify>
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            color="primary"
                            onClick={finishCrop}
                            sx={{
                              bgcolor: 'secondary.main', // A neutral background to start
                              ':hover': {
                                bgcolor: 'secondary.main', // A neutral background to start
                                transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                              },
                              transition: 'all 0.3s ease', // Smooth transition for the hover effects
                              borderRadius: '50%', // Keeps the circular shape
                              border: '2px solid', // Add a border for definition
                              borderColor: 'primary.main', // Use the theme's error color for the border
                              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                              '&:active': {
                                transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                              },
                            }}
                          >
                            <Iconify sx={{ width: 25, height: 25 }} icon="ph:check"></Iconify>
                          </IconButton>
                        )}
                      </Box>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Camera
                      idealFacingMode={FACING_MODES.ENVIRONMENT}
                      isImageMirror={false}
                      isFullscreen={false}
                      onTakePhoto={(dataUri: string) => {
                        handleTakePhoto(dataUri);
                      }}
                    />
                  </>
                )}
              </div>
            ) : (
              <>
                {screenshot64String !== '' ? (
                  <>
                    <Box sx={{ position: 'relative' }}>
                      <IconButton
                        size="small"
                        onClick={handleDeleteFile}
                        sx={{
                          top: 16,
                          right: 16,
                          zIndex: 9,
                          position: 'absolute',
                          color: (theme) => alpha(theme.palette.common.white, 0.8),
                          bgcolor: (theme) => alpha(theme.palette.common.black, 0.72),
                          '&:hover': {
                            bgcolor: (theme) => alpha(theme.palette.common.black, 0.48),
                          },
                        }}
                      >
                        <Iconify icon="eva:close-fill" width={18} />
                      </IconButton>
                      {!crop ? (
                        <Image
                          alt="file preview"
                          ratio="16/9"
                          objectFit="contain"
                          src={screenshot64String}
                          sx={{
                            objectFit: 'contain', // Use 'contain' to maintain the aspect ratio and fill the container
                            objectPosition: 'center', // Center the image within the box
                            width: 'calc(100% - 16px)',
                            height: 'calc(100% - 16px)',
                            minHeight: '400px',
                          }}
                        />
                      ) : (
                        <Cropper
                          src={screenshot64String}
                          style={{
                            width: 'calc(100% - 16px)',
                            height: 'calc(100% - 16px)',
                            minHeight: '400px',
                            objectFit: 'contain', // Use 'contain' to maintain the aspect ratio and fill the container
                            objectPosition: 'center', // Center the image within the box
                          }}
                          // Cropper.js options
                          initialAspectRatio={16 / 9}
                          guides={true}
                          crop={onCrop}
                          ref={cropperRef}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        minWidth: 'fit-content', // Ensure the box can fit its content
                        borderRadius: theme.palette.borders.borderRadius,
                        top: 20, // Position so the guidance box is just above the main box
                        left: '50%',
                        transform: 'translateX(-50%)', // Center horizontally
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'rgba(51, 60, 75, 0.33)', // Using transparent black for darker blur
                        backdropFilter: 'blur(10px)', // blur effect
                        padding: '12px', // Padding around the text
                      }}
                    >
                      <Typography
                        variant="body2" // Smaller text variant
                        color="background.paper" // Ensuring the text is visible on the darker background
                        textAlign="center"
                      >
                        {`${translate('ai.file.cropTip')}`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        width: 'fit-content',
                        borderRadius: '25px',
                        bottom: 20, // Adjust this value to move the box higher or lower
                        left: '50%',
                        transform: 'translateX(-50%)', // Center horizontally
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100px', // Adjust based on your needs
                        bgcolor: 'rgba(51, 60, 75, 0.33)', // Using transparent black for darker blur
                        backdropFilter: 'blur(10px)', // Apply blur effect
                        gap: '40px',
                      }}
                    >
                      {' '}
                      {!crop ? (
                        <>
                          {' '}
                          <IconButton
                            color="info"
                            onClick={() => setCrop(true)}
                            sx={{
                              // bgcolor: 'background.paper', // A neutral background to start
                              ':hover': {
                                transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                              },
                              background: 'white',

                              transition: 'all 0.3s ease', // Smooth transition for the hover effects
                              borderRadius: '50%', // Keeps the circular shape
                              border: '2px solid', // Add a border for definition
                              borderColor: 'info.main', // Use the theme's error color for the border
                              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                              '&:active': {
                                transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                              },
                            }}
                          >
                            <Iconify sx={{ width: 25, height: 25 }} icon="ph:crop"></Iconify>
                          </IconButton>
                          <IconButton
                            color="primary"
                            onClick={() => {
                              handleUploadDesktop();
                            }}
                            sx={{
                              bgcolor: 'secondary.main', // A neutral background to start
                              ':hover': {
                                bgcolor: 'secondary.main', // A neutral background to start
                                transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                              },
                              transition: 'all 0.3s ease', // Smooth transition for the hover effects
                              borderRadius: '50%', // Keeps the circular shape
                              border: '2px solid', // Add a border for definition
                              borderColor: 'primary.main', // Use the theme's error color for the border
                              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                              '&:active': {
                                transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                              },
                            }}
                          >
                            <Iconify
                              sx={{ width: 25, height: 25, transform: 'rotate(130deg)' }}
                              icon="ph:navigation-arrow"
                            ></Iconify>
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          color="primary"
                          onClick={finishCrop}
                          sx={{
                            bgcolor: 'secondary.main', // A neutral background to start
                            ':hover': {
                              bgcolor: 'secondary.main', // A neutral background to start
                              transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                            },
                            transition: 'all 0.3s ease', // Smooth transition for the hover effects
                            borderRadius: '50%', // Keeps the circular shape
                            border: '2px solid', // Add a border for definition
                            borderColor: 'primary.main', // Use the theme's error color for the border
                            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                            '&:active': {
                              transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                            },
                          }}
                        >
                          <Iconify sx={{ width: 25, height: 25 }} icon="ph:check"></Iconify>
                        </IconButton>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Webcam
                      open={open}
                      audio={false}
                      mirrored={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                    />
                    {/* Guidance Box for taking a photo */}
                    <Box
                      sx={{
                        position: 'absolute',
                        minWidth: 'fit-content', // Ensure the box can fit its content
                        borderRadius: '25px',
                        bottom: 130, // Position so the guidance box is just above the main box
                        left: '50%',
                        transform: 'translateX(-50%)', // Center horizontally
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: 'rgba(51, 60, 75, 0.33)', // Using transparent black for darker blur
                        backdropFilter: 'blur(10px)', // blur effect
                        padding: '12px', // Padding around the text
                      }}
                    >
                      <Typography
                        variant="body2" // Smaller text variant
                        color="background.paper" // Ensuring the text is visible on the darker background
                        textAlign="center"
                      >
                        {`${translate('ai.camera.takePhoto')}`}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        width: '33%',
                        borderRadius: '25px',
                        bottom: 20, // Adjust this value to move the box higher or lower
                        left: '50%',
                        transform: 'translateX(-50%)', // Center horizontally
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100px', // Adjust based on your needs
                        bgcolor: 'rgba(51, 60, 75, 0.33)', // Using transparent black for darker blur
                        backdropFilter: 'blur(10px)', // Apply blur effect
                        gap: '40px',
                      }}
                    >
                      <IconButton
                        color="info"
                        component="span"
                        onClick={() => {
                          // console.log('take photo');

                          setOpen(false);
                          setOpenGallery(true);
                        }}
                        sx={{
                          // bgcolor: 'background.paper', // A neutral background to start
                          ':hover': {
                            transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                          },
                          transition: 'all 0.3s ease', // Smooth transition for the hover effects
                          borderRadius: '50%', // Keeps the circular shape
                          border: '2px solid', // Add a border for definition
                          borderColor: 'info.main', // Use the theme's error color for the border
                          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                          '&:active': {
                            transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                          },
                        }}
                      >
                        <Iconify sx={{ width: 25, height: 25 }} icon="ph:image"></Iconify>
                      </IconButton>

                      <IconButton
                        color="inherit"
                        aria-label="take photo"
                        component="span"
                        onClick={() => {
                          // console.log('take photo');
                          capture();
                        }}
                        sx={{
                          bgcolor: 'secondary.main', // A neutral background to start
                          ':hover': {
                            // bgcolor: 'error.main', // A deeper shade on hover for contrast
                            transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                          },
                          transition: 'all 0.3s ease', // Smooth transition for the hover effects
                          borderRadius: '50%', // Keeps the circular shape
                          border: '2px solid', // Add a border for definition
                          borderColor: 'common.black', // Use the theme's error color for the border
                          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                          '&:active': {
                            transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                          },
                        }}
                      >
                        <Iconify sx={{ width: 50, height: 50, p: 1 }} icon="ph:camera"></Iconify>
                      </IconButton>
                      <IconButton
                        color="error"
                        component="span"
                        onClick={() => {
                          setOpen(false);
                        }}
                        sx={{
                          // bgcolor: 'background.paper', // A neutral background to start
                          ':hover': {
                            transform: 'scale(1.1)', // Slightly enlarge the button on hover for a dynamic effect
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)', // Soft shadow for depth
                            borderColor: 'error.main', // Use the theme's error color for the border
                          },
                          transition: 'all 0.3s ease', // Smooth transition for the hover effects
                          borderRadius: '50%', // Keeps the circular shape
                          border: '2px solid', // Add a border for definition
                          borderColor: 'error.main', // Use the theme's error color for the border
                          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                          '&:active': {
                            transform: 'scale(0.95)', // Slightly shrink the button when clicked for a tactile feel
                            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Adjust the shadow for an active state
                          },
                        }}
                      >
                        <Iconify sx={{ width: 25, height: 25 }} icon="carbon:close"></Iconify>
                      </IconButton>
                    </Box>
                  </>
                )}
              </>
            )}
          </Dialog>
        </>
      )}
    </>
  );
}
