import axios from './axios';
import * as Sentry from '@sentry/react';

export async function getLessonUrlStudent(
  id: number,
  setUrlForLesson: (value: string) => void,
  setErrorLesson: (value: string) => void
) {
  await axios
    .get(`/api/get_lesson_space_url_student/${id}`)
    .then((response) => {
      setUrlForLesson(response.data.url);
    })
    .catch((error) => {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        if (error.response.status === 404) {
          setErrorLesson('Lesson not found');
        } else if (error.response.status === 500) {
          setErrorLesson('Internal server error');
        } else {
          setErrorLesson('An unexpected error occurred');
        }
      } else {
        setErrorLesson(error.toString());
      }
    });
}

export async function getLessonUrlTeacher(
  id: number,
  setUrlForLesson: (value: string) => void,
  setErrorLesson: (value: string) => void
) {
  await axios
    .get(`/api/get_lesson_space_url_teacher/${id}`)
    .then((response) => {
      setUrlForLesson(response.data.url);
    })
    .catch((error) => {
      console.log(error);
      Sentry.captureException(error);

      if (error.response) {
        if (error.response.status === 404) {
          setErrorLesson('Lesson not found');
        } else if (error.response.status === 500) {
          setErrorLesson('Internal server error');
        } else {
          setErrorLesson('An unexpected error occurred');
        }
      } else {
        setErrorLesson(error.toString());
      }
    });
}
