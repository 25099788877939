import * as Yup from 'yup';
import merge from 'lodash/merge';
import { isBefore } from 'date-fns';
import { EventInput } from '@fullcalendar/core';
import { Link } from 'react-router-dom';

// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from '../../../locales';

// @mui
import {
  Box,
  Stack,
  Button,
  TextField,
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  Dialog,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

// @types
import { IStudent } from '../../../@types/student';
import { ICalendarEvent } from '../../../@types/calendar';
import { ITutor } from '../../../@types/tutor';
// components
import Iconify from '../../../components/iconify';
import { useSnackbar } from '../../../components/snackbar';

import FormProvider, {
  RHFTextField,
  RHFSwitch,
  RHFAutocomplete,
} from '../../../components/hook-form';
import { SendLessonReminder } from '../lessons';
import { CancelLessonDialog } from './CancelLessonDialog';
import { CompleteLessonDialog } from '../lessons';
import { useState } from 'react';
//redux
import { completeLesson, createEvent } from '../../../redux/slices/calendar';
import { useDispatch, useSelector } from '../../../redux/store';
import { useAuthContext } from 'src/auth/useAuthContext';
import { cancelLessonRequest } from '../../../redux/slices/lessonRequests';
// ----------------------------------------------------------------------

type FormValuesProps = ICalendarEvent;

type Props = {
  event: EventInput | null | undefined;
  admin: boolean;
  editable: boolean;
  teachers: [];
  students: [];
  cancel: boolean;
  range: {
    start: Date;
    end: Date;
  } | null;
  onClose: VoidFunction;
  onCancel: (state: boolean) => void;
  cancelLesson: (reason: string) => void;
  onCreateUpdateEvent: (newEvent: ICalendarEvent) => void;
  onDelete: (eventId: string) => void;
  useGetEvents?: (date: Date | null, refresh?: number) => ICalendarEvent[];
  date?: Date;
};

// ----------------------------------------------------------------------

const getInitialValues = (
  event: EventInput | null | undefined,
  range: { start: Date; end: Date } | null
) => {
  const initialEvent: FormValuesProps = {
    title: '',
    title_data: '',
    description: '',
    color: '#1890FF',
    completionNotes: '',
    studentId: { code: '', label: '', priority: '' },
    studentName: '',
    teacherId: { code: '', label: '', priority: '' },
    teacherName: '',
    paid: false,
    trial_lesson: false,
    allDay: false,
    start: range ? new Date(range.start).toString() : new Date().toString(),
    end: range ? new Date(range.end).toString() : new Date().toString(),
    recurring: false,
  };

  if (event || range) {
    return merge({}, initialEvent, event);
  }

  return initialEvent;
};

// ----------------------------------------------------------------------
/**
 * @component
 *
 * @param {Props} {
 * event,
 * range,
 * admin,
 * editable,
 * teachers,
 * students,
 * cancel,
 * onCreateUpdateEvent,
 * onDelete
 * onClose,
 * onCancel,
 * cancelLesson,
 *  }
 * @returns  {JSX.Element} The rendered ViewLessonForm component. This is used to view Lessons in the Calendar
 */
export default function ViewLessonForm({
  event,
  range,
  admin,
  editable,
  teachers,
  students,
  cancel,
  onCreateUpdateEvent,
  onDelete,
  onClose,
  onCancel,
  cancelLesson,
  useGetEvents = () => [],
  date = new Date(),
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { user } = useAuthContext();
  const dispatch = useDispatch();

  const [openCancelRequestDialog, setOpenCancelRequestDialog] = useState(false);
  const [refreshEvents, setRefreshEvents] = useState<number>(0);

  const EventSchema = Yup.object().shape({
    title_data: Yup.string()
      .min(2, translate('lesson.titleData'))
      .max(255)
      .required(translate('lesson.titleData')),
    description: Yup.string().max(5000),
    teacherId: Yup.object().shape({
      code: Yup.string().required(),
      label: Yup.string().required(),
    }),
    studentId: Yup.object().shape({
      code: Yup.string().required(),
      label: Yup.string().required(),
    }),
    // start:Yup.date().min(new Date(),'Lesson cannot be created earlier'),
    // end:Yup.date().min(new Date(),'Lesson cannot be created earlier'),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range),
  });

  const { error } = useSelector((state) => state.calendar);

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const startDateISO = new Date(data?.start || new Date()).toISOString();
      const endDateISO = new Date(data?.end || new Date()).toISOString();
      const newEvent = {
        title: data.title_data,
        title_data: data.title_data,
        description: data.description,
        color: data.color,
        allDay: data.allDay,
        paid: data.paid,
        completionNotes: data.completionNotes,
        studentId: data.studentId,
        studentName: data.studentName,
        teacherName: data.teacherName,
        teacherId: data.teacherId,
        trial_lesson: data.trial_lesson,
        start: startDateISO,
        end: endDateISO,
        recurring: data.recurring,
        request: false,
      };
      onCreateUpdateEvent(newEvent);
      onClose();
      reset();
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(values);
  /**
   * Sorts and allows the RHFSelect element to accept the data
   * @param studentsArray Initial teacher array
   * @returns a sorted list of the teachers, the array has been mapped to fit the RHFSelect elemetn
   */
  const sortArrayTeachers = (
    teachersArray: ITutor[]
  ): { code: string; label: string; priority: string }[] => {
    const arrayForSorting = teachersArray.map((value) => {
      const priority = value.students.includes(values.studentId.code);
      return {
        code: value.id.toString(),
        label: `${value.first_name} ${value.last_name}`,
        priority: priority
          ? String(translate('calender.assigned'))
          : String(translate('calender.all')),
      };
    });

    return arrayForSorting.sort((a) => {
      if (a.priority === String(translate('calender.assigned'))) {
        return -1;
      }
      return 1;
    });
  };
  /**
   * Sorts and allows the RHFSelect element to accept the data
   * @param studentsArray Initial Student array
   * @returns a sorted list of the students, the array has been mapped to fit the RHFSelect elemetn
   */
  const sortArrayStudents = (
    studentsArray: IStudent[]
  ): { code: string; label: string; priority: string }[] => {
    const arrayForSorting = studentsArray.map((value) => {
      const priority = value.teachers.includes(values.teacherId.code);
      return {
        code: value.id.toString(),
        label: `${value.first_name} ${value.last_name}`,
        priority: priority
          ? String(translate('calender.assigned'))
          : String(translate('calender.all')),
      };
    });
    return arrayForSorting.sort((a) => {
      if (a.priority === String(translate('calender.assigned'))) {
        return -1;
      }
      return 1;
    });
  };

  // Call the hook with the date and the refresh trigger if the useGetEvents is given.
  useGetEvents(date, refreshEvents);

  // Handles everything related to completing the lesson.
  const sendLessonCompletion = async (completionNotes: string) => {
    const response = await dispatch(
      completeLesson(admin, parseInt(event?.id || '', 10), completionNotes)
    );
    if (response) {
      enqueueSnackbar(`${translate('snackBar.registered')}`, { variant: 'success' });
    } else {
      enqueueSnackbar(`${translate('snackBar.lesson')}`, { variant: 'error' });
    }

    onClose();
    setRefreshEvents(Date.now()); // This will trigger a refresh of events
  };

  // Creates a new lesson if they choose to plan it right away.
  const handleCreateUpdateEvent = async (newEvent: ICalendarEvent) => {
    await dispatch(createEvent(newEvent));
  };
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentEndTime, setCurrentEndTime] = useState<Date>();
  const isDateError = () => {
    const today = new Date();
    today.setHours(23, 59, 59);
    const monthMiddle = new Date(today.getFullYear(), today.getMonth(), 15, 23, 59, 59);
    const checkAgeEnd = new Date(values?.end?.toString() || new Date());
    const checkAgeStart = new Date(values?.start?.toString() || new Date());
    const timeDiff = (today.getTime() - checkAgeEnd.getTime()) / (60 * 60 * 1000 * 24) > 8;
    if (values.start && values.end) {
      if (admin && !values.paid) {
        return {
          value: false,
          message: 'No errors',
        };
      }
      if ((checkAgeEnd.getTime() - checkAgeStart.getTime()) / (60 * 60 * 1000) > 10) {
        return { value: true, message: `${translate('snackBar.lessonLength')}` };
      } else if (timeDiff && editable) {
        // console.log('Check');
        return {
          value: true,
          message: `${translate('snackBar.lessonChange')}`,
        };
      } else if (
        checkAgeStart.getTime() <= monthMiddle.getTime() &&
        today.getDate() > 15 &&
        !admin &&
        timeDiff
      ) {
        // console.log('Check2');
        return {
          value: true,
          message: `${translate('snackBar.lessonChange')}`,
        };
      }
      return {
        value: isBefore(new Date(values.end), new Date(values.start)),
        message: `${translate('snackBar.lessonEndMessage')}`,
      };
    }
    return { value: false, message: 'No errors' };
  };

  const stateEdit = editable;
  const [openCompleteLessonDialog, setOpenCompleteLessonDialog] = useState(false);

  // Function to handle cancelling a lesson request
  const handleCancelRequest = async (reason: string) => {
    if (event) {
      try {
        // Create a compatible object for the cancelLessonRequest function
        const lessonRequestObj = {
          id: event.id?.toString() || '',
          status: event.status,
          // Add other required properties with defaults
          students: [],
          teacher_id: event.teacherId?.code || '',
          student_first_name: event.studentName?.split(' ')[0] || '',
          student_last_name: event.studentName?.split(' ')[1] || '',
          from_time: event.start || new Date(),
          to_time: event.end || new Date(),
          trial_lesson_request: event.trial_lesson || false,
          recurring: event.recurring || false,
        };

        const result = await dispatch(cancelLessonRequest(lessonRequestObj.id, reason));
        if (result) {
          enqueueSnackbar(`${translate('snackBar.requestCancelled')}`, { variant: 'success' });
          onClose();
          if (setRefreshEvents) {
            setRefreshEvents(Date.now()); // Trigger refresh
          }
        } else {
          enqueueSnackbar('Failed to cancel request', { variant: 'error' });
        }
      } catch (error) {
        console.error('Error cancelling request:', error);
        enqueueSnackbar('Error cancelling request', { variant: 'error' });
      }
    }
  };
  console.log(event);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <RHFTextField
          disabled={stateEdit || !!isDateError().value}
          name="title_data"
          label={`${translate('calender.title')}`}
        />

        <RHFTextField
          disabled={stateEdit || !!isDateError().value}
          name="description"
          label={`${translate('calender.description')}`}
          multiline
          rows={3}
        />

        {/* If lesson has been completed then show completion notes. */}
        {event?.status == 'attended' && (
          <RHFTextField
            disabled={stateEdit || !!isDateError().value}
            name="completionNotes"
            label={`${translate('calender.report')}`}
            multiline
            rows={7}
          />
        )}
        <Stack direction={'row'} spacing={1} justifyContent="space-between">
          <RHFSwitch
            onClick={(e) => {
              const value = (e.target as HTMLInputElement).value.toLowerCase() === 'false';
              const currentEndTimeLesson = new Date(values.end);
              console.log(value);
              if (value) {
                setCurrentEndTime(currentEndTimeLesson);
                const startTime = new Date(values.start);
                const endTime = new Date(startTime.getTime() + 30 * 60000);
                setValue('end', endTime);
              } else {
                console.log(currentEndTime);
                setCurrentEndTime(currentEndTimeLesson);
                if (currentEndTime) {
                  setValue('end', currentEndTime);
                }
              }
            }}
            disabled={stateEdit || !!isDateError().value}
            name="trial_lesson"
            label={`${translate('calender.startMeeting')}`}
          />
          {event?.status === 'scheduled' && !editable ? (
            <SendLessonReminder id={values?.id} />
          ) : (
            <></>
          )}
        </Stack>

        <RHFAutocomplete
          disableClearable
          autoComplete
          autoHighlight
          required
          groupBy={(option) => option.priority}
          disabled
          label={`${translate('calender.student')}`}
          name="studentId"
          options={sortArrayStudents([...students])}
          ChipProps={{ size: 'small' }}
          noOptionsText={`${translate('calender.noOptions')}`}
        />
        <RHFAutocomplete
          autoComplete
          autoHighlight
          disableClearable
          required
          groupBy={(option) => option.priority}
          disabled
          label={`${translate('lesson.tutorTable')}`}
          name="teacherId"
          options={sortArrayTeachers([...teachers])}
          ChipProps={{ size: 'small' }}
          noOptionsText={`${translate('calender.noOptions')}`}
        />
        <Controller
          name="start"
          control={control}
          render={({ field }) => (
            <MobileDateTimePicker
              ampm={false}
              ampmInClock={false}
              disabled={stateEdit || !!isDateError().value}
              {...field}
              onChange={(newValue: Date | null) => {
                field.onChange(newValue);
                if (newValue) {
                  const duration = values.trial_lesson ? 30 : 120;
                  const endTime = new Date(newValue.getTime() + duration * 60000);
                  setValue('end', endTime);
                }
              }}
              label={`${translate('earnings.lessonTableHeaderStartTime')}`}
              minutesStep={15}
              openTo="day"
              views={['day', 'hours', 'minutes']}
              renderInput={(params) => <TextField disabled {...params} fullWidth />}
            />
          )}
        />

        <Controller
          name="end"
          control={control}
          render={({ field }) => (
            <MobileDateTimePicker
              {...field}
              disabled={stateEdit || !!isDateError().value}
              ampm={false}
              ampmInClock={false}
              onChange={(newValue: Date | null) => field.onChange(newValue)}
              label={`${translate('earnings.lessonTableHeaderEndTime')}`}
              minutesStep={15}
              openTo="day"
              views={['day', 'hours', 'minutes']}
              renderInput={(params) => (
                <TextField
                  disabled={editable}
                  {...params}
                  fullWidth
                  error={!!isDateError().value}
                  helperText={isDateError().value && isDateError().message}
                />
              )}
            />
          )}
        />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <RHFSwitch
            disabled={stateEdit || !!isDateError().value || event?.status !== 'scheduled'}
            name="recurring"
            label={`${translate('lesson.planLesson')}`}
            checked={values?.recurring}
          />
          <Tooltip title={`${translate('lesson.planLessonMessage')}`}>
            <Iconify icon="ic:outline-info"></Iconify>
          </Tooltip>
        </Box>
      </Stack>

      <Box sx={{ flexGrow: 1 }} />
      <DialogActions>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Button
              to={`/lesson/${event?.id}/details`}
              target={event?.status === 'scheduled' && !admin ? `_blank` : ``}
              component={Link}
              disabled={event?.request}
              variant="contained"
              fullWidth
              size="large"
            >
              {`${translate('overview.seeLesson')}`}
            </Button>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <>
              {/* Add cancel button for pending lesson requests for students */}
              {event?.request &&
                event?.status === 'pending' &&
                (user?.student || user?.customer) && (
                  <>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        fullWidth
                        color="error"
                        onClick={() => setOpenCancelRequestDialog(true)}
                      >
                        {`${translate('lesson.cancelLesson')}`}
                      </Button>
                    </Grid>
                  </>
                )}

              {/* Cancel request dialog */}
              <Dialog
                fullWidth
                maxWidth="xs"
                open={openCancelRequestDialog}
                onClose={() => setOpenCancelRequestDialog(false)}
              >
                <DialogTitle>
                  {`${translate('lesson.cancelThisLesson')}`}
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenCancelRequestDialog(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Iconify icon="ic:sharp-close" />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {`${translate('lesson.cancelLessonTitleNonAdmin')}`}
                  </Typography>
                  <RHFTextField
                    name="cancellation_reason"
                    label={`${translate('lesson.cancelLessonTitle')}`}
                    multiline
                    rows={3}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenCancelRequestDialog(false)}>
                    {`${translate('lessonPlans.deleteCancel')}`}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      const reason = methods.getValues('cancellation_reason') || '';
                      handleCancelRequest(reason);
                      setOpenCancelRequestDialog(false);
                    }}
                  >
                    {`${translate('lessonPlans.deleteConfirm')}`}
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Display cancelled status for rejected requests that were cancelled by the student */}
              {event?.request &&
                event?.status === 'rejected' &&
                event?.rejection_reason &&
                event?.rejection_reason.startsWith('CANCELLED BY STUDENT:') &&
                (user?.student || user?.customer) && (
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ p: 2, bgcolor: 'background.neutral', borderRadius: 1 }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          {`${translate('snackBar.requestCancelled')}`}
                        </Typography>
                        <Typography variant="body2">
                          {event.rejection_reason.replace('CANCELLED BY STUDENT: ', '')}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}

              {event?.status !== 'scheduled' || !editable || !!isDateError().value ? (
                <></>
              ) : (
                <>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="error"
                      onClick={() => onCancel(true)}
                    >
                      {`${translate('lesson.cancelLesson')}`}
                    </Button>
                  </Grid>
                </>
              )}

              {event?.status !== 'scheduled' || editable || !!isDateError().value ? (
                <>
                  {event?.status === 'attended' ? (
                    <>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          fullWidth
                          color="error"
                          onClick={() => onCancel(true)}
                        >
                          {`${translate('lesson.cancelLesson')}`}
                        </Button>
                      </Grid>
                      <CancelLessonDialog
                        admin={!editable}
                        cancel={cancel}
                        onCancel={onCancel}
                        cancelLesson={cancelLesson}
                        recurring={values?.recurring}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="error"
                      onClick={() => onCancel(true)}
                    >
                      {`${translate('lesson.cancelLesson')}`}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        if (!isDateError().value) {
                          setOpenCompleteLessonDialog(true);
                        }
                      }}
                    >
                      {`${translate('tutor.title')}`}
                    </Button>
                  </Grid>
                  <CancelLessonDialog
                    admin={!editable}
                    cancel={cancel}
                    onCancel={onCancel}
                    cancelLesson={cancelLesson}
                    recurring={values?.recurring}
                  />
                  <CompleteLessonDialog
                    event={event}
                    teachers={teachers}
                    students={students}
                    error={error}
                    finishLessson={sendLessonCompletion}
                    handleCreateUpdateEvent={handleCreateUpdateEvent}
                    cancel={openCompleteLessonDialog}
                    admin={admin}
                    onCancel={setOpenCompleteLessonDialog}
                    recurring={event?.recurring}
                  />
                </>
              )}

              <Dialog
                fullWidth
                maxWidth="xs"
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
              >
                <DialogTitle>
                  Are you sure?
                  <IconButton
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    color="error"
                    onClick={() => setOpenDeleteDialog(false)}
                  >
                    <Iconify icon="ic:sharp-close" />
                  </IconButton>
                </DialogTitle>
                <DialogContent></DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (event) {
                        onDelete(event?.id || '');
                        setOpenDeleteDialog(false);
                        onClose();
                      }
                    }}
                  >
                    Yes
                    {parseInt(event?.id || '') < 100000000
                      ? '(This is a techworks lesson so please delete it from there as well'
                      : ''}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenDeleteDialog(false)}
                  >
                    No
                  </Button>
                </DialogActions>
              </Dialog>
              <Grid item xs={6}>
                <LoadingButton
                  disabled={editable || !!isDateError().value}
                  type="submit"
                  variant="outlined"
                  fullWidth
                  loading={isSubmitting}
                >
                  {`${translate('customer.saveChanges')}`}
                </LoadingButton>
              </Grid>
              {admin && !values?.paid && !isDateError().value ? (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    disabled={editable || values?.paid}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      if (parseInt(event?.id || '') < 100000000 && !user?.superadmin) {
                        enqueueSnackbar(
                          'This is a teachworks lesson so be careful! Do not delete it except with superadmin permission.',
                          { variant: 'warning' }
                        );
                      } else {
                        setOpenDeleteDialog(true);
                      }
                    }}
                  >
                    {`${translate('overview.delete')}`}{' '}
                  </Button>
                </Grid>
              ) : (
                <></>
              )}
            </>
          </Grid>
        </Grid>
      </DialogActions>
    </FormProvider>
  );
}
