import { Box, Popover } from '@mui/material';
import { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useLocales } from 'src/locales';
import SurveyRating from './SurveyRating';
import SurveySingleChoice from './SurveySingleChoice';
import { useDispatch } from 'src/redux/store';
import { addUserSurvey } from 'src/redux/slices/user';

export default function SurveyPopover({ showSurvey, survey, setShowSurvey, setStopCallback }: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showConfirmMessage, setShowConfirmMessage] = useState<boolean>(false);
  const posthog = usePostHog();
  const { user } = useAuthContext();
  const { translate, currentLang } = useLocales();
  const dispatch = useDispatch();

  const handlePopoverOpen = () => {
    const boxElement = document.getElementById('survey-box');
    setAnchorEl(boxElement);
  };

  const handlePopoverClose = () => {
    setShowSurvey(false);
    setAnchorEl(null);
    setStopCallback(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSurveySubmit = (surveyResponse: string | number) => {
    if (!surveyResponse) return;

    posthog.capture('survey sent', {
      $survey_id: survey.survey_id,
      $survey_response: surveyResponse,
    });

    const data = {
      user_id: user?.uid,
      survey_id: survey.id,
      status: 'survey sent',
    };

    dispatch(addUserSurvey(data));

    setShowConfirmMessage(true);

    setTimeout(() => {
      handlePopoverClose();
    }, 2000);
  };

  const handleSurveyDismiss = () => {
    posthog.capture('survey dismissed', {
      $survey_id: survey.survey_id,
    });

    const data = {
      user_id: user?.uid,
      survey_id: survey.id,
      status: 'survey dismissed',
    };

    dispatch(addUserSurvey(data));

    handlePopoverClose();
  };

  useEffect(() => {
    if (user) {
      posthog.identify(user?.uid, {
        email: user?.email,
        name: user?.name,
      });
    }
  }, [posthog, user]);

  useEffect(() => {
    if (showSurvey) {
      setShowConfirmMessage(false);
      setTimeout(() => {
        handlePopoverOpen();
      }, 300);
    }
  }, [showSurvey]);

  return (
    <>
      <Box
        id="survey-box"
        sx={{ position: 'absolute', bottom: 0, right: 0 }}
        aria-describedby={id}
      ></Box>
      <Popover
        id={id}
        style={{ position: 'absolute', borderRadius: 0 }}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
      >
        {showConfirmMessage && (
          <Box sx={{ p: 3 }}>{`${translate('survey.after_survey_message')}`}</Box>
        )}
        {(() => {
          if (!showConfirmMessage && showSurvey) {
            if (survey.type === 'rating') {
              return (
                <SurveyRating
                  selectedSurvey={survey}
                  submitSurvey={handleSurveySubmit}
                  dismissSurvey={handleSurveyDismiss}
                />
              );
            } else if (survey.type === 'single_choice') {
              return (
                <SurveySingleChoice
                  selectedSurvey={survey}
                  submitSurvey={handleSurveySubmit}
                  dismissSurvey={handleSurveyDismiss}
                />
              );
            }
          }
        })()}
      </Popover>
    </>
  );
}
