export const onboardingCards = {
  premium: [
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
  ],
  ai: [
    {
      title: 'onboarding.AITitle1',
      text: 'onboarding.AIText1',
    },
    {
      title: 'onboarding.AITitle2',
      text: 'onboarding.AIText2',
    },
    {
      title: 'onboarding.AITitle3',
      text: 'onboarding.AIText3',
    },
    {
      title: 'onboarding.AITitle4',
      text: 'onboarding.AIText4',
    },
    {
      title: 'onboarding.AITitle5',
      text: 'onboarding.AIText5',
    },
  ],
  visitor: [
    {
      title: 'onboarding.visitorTitle1',
      text: 'onboarding.visitorText1',
    },
    {
      title: 'onboarding.visitorTitle2',
      text: 'onboarding.visitorText2',
    },
    {
      title: 'onboarding.visitorTitle3',
      text: 'onboarding.visitorText3',
    },
    {
      title: 'onboarding.visitorTitle4',
      text: 'onboarding.visitorText4',
    },
    {
      title: 'onboarding.visitorTitle5',
      text: 'onboarding.visitorText5',
    },
  ],
  students: [
    {
      title: 'onboarding.studentsTitle1',
      text: 'onboarding.studentsText1',
    },
    {
      title: 'onboarding.studentsTitle2',
      text: 'onboarding.studentsText2',
    },
    {
      title: 'onboarding.studentsTitle3',
      text: 'onboarding.studentsText3',
    },
    {
      title: 'onboarding.studentsTitle4',
      text: 'onboarding.studentsText4',
    },
    {
      title: 'onboarding.studentsTitle5',
      text: 'onboarding.studentsText5',
    },
    {
      title: 'onboarding.studentsTitle6',
      text: 'onboarding.studentsText6',
    },
  ],
  tutors: [
    {
      title: 'onboarding.tutorTitle1',
      text: 'onboarding.tutorText1',
    },
    {
      title: 'onboarding.tutorTitle2',
      text: 'onboarding.tutorText2',
    },
    {
      title: 'onboarding.tutorTitle3',
      text: 'onboarding.tutorText3',
    },
    {
      title: 'onboarding.tutorTitle4',
      text: 'onboarding.tutorText4',
    },
  ],
};
