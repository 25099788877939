import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';
import { useLocales } from 'src/locales';

// @mui
import { Container, CircularProgress, Typography, Grid, Card, Box, Button } from '@mui/material';

// auth
import { useDispatch, useSelector } from '../../../redux/store';
import { getInvoiceHashed } from '../../../redux/slices/invoice';

import MembershipLayout from '../../../layouts/membeship';
import { fCurrency } from '../../../utils/formatNumber';
import { useAuthContext } from 'src/auth/useAuthContext';
// ----------------------------------------------------------------------

export default function OrderConfirmationPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { invoice, isLoading } = useSelector((state) => state.invoice);
  const { onChangeLang } = useLocales();

  const theme = useTheme();
  const { translate } = useLocales();

  useEffect(() => {
    if (invoice) {
      if (!user) {
        onChangeLang(invoice.language.toLowerCase());
      }
    }
  }, [invoice]);

  useEffect(() => {
    if (id) {
      dispatch(getInvoiceHashed(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (invoice && invoice.membership) {
      // Redirect to membership confirmation if it's a membership order
      window.location.href = `/membership/confirmation/${invoice.hashed_id}`;
    }
  }, [invoice]);
  // Remove or keep commented the redirect useEffect since we want to show non-membership orders here

  return (
    <>
      <Helmet>
        <title>{`${translate('helmet.orderConfirmed')}`}</title>
      </Helmet>
      <Container maxWidth={'xl'}>
        <MembershipLayout>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={1} sx={{ mb: 10 }}>
              <Grid item xs={12} md={7}>
                <Card
                  sx={{
                    width: '100%',
                    p: 2,
                    [theme.breakpoints.up('md')]: { borderRadius: '20px 0px 0px 20px' },
                  }}
                >
                  <Box rowGap={2} columnGap={2} display="grid" width={'100%'}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ mt: '20px', textAlign: 'center' }}
                        variant="h3"
                        color={'primary'}
                      >
                        {`${translate('orders.confirmation.title')}`}
                      </Typography>
                      <Typography sx={{ m: '10px', textAlign: 'center' }} variant="body1">
                        {`${translate('memberships.confirmation.p1')}`}
                      </Typography>
                      <Typography variant="body1" sx={{ mt: '40px', textAlign: 'center' }}>
                        {`${translate('memberships.confirmation.p2')}`}
                      </Typography>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          href="/dashboard"
                          variant="contained"
                          size="large"
                          color="primary"
                          sx={{ my: 5 }}
                        >
                          {`${translate('authLoginForm.login')}`}
                        </Button>
                      </div>
                    </Grid>
                  </Box>
                </Card>
              </Grid>

              <Grid style={{ paddingLeft: '0px' }} item xs={12} md={5}>
                <Card
                  sx={{
                    p: 2,
                    backgroundColor: theme.palette.background.neutral,
                    height: '100%',
                    [theme.breakpoints.up('md')]: { borderRadius: '0px 20px 20px 0px' },
                  }}
                >
                  <Box rowGap={3} columnGap={2} display="grid">
                    <Grid container direction="row" justifyContent="space-evenly" spacing={1}>
                      <Grid item xs={12}>
                        <Typography sx={{ mb: '10px' }} variant="h5">
                          {`${translate('memberships.orderOverview')}`}
                        </Typography>
                      </Grid>

                      {/* Price per hour */}
                      <Grid item xs={8}>
                        <Typography color={alpha(theme.palette.common.black, 0.75)}>
                          {`${translate('orders.pricePerHour')}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography color={theme.palette.common.black}>
                          {`${fCurrency(invoice?.unit_price || 0)} ${invoice?.currency}`}
                        </Typography>
                      </Grid>

                      {/* Total Hours */}
                      <Grid item xs={8}>
                        <Typography color={alpha(theme.palette.common.black, 0.75)}>
                          {`${translate('customer.membership.numberOfHours')}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography color={theme.palette.common.black}>
                          {invoice?.total_hours}
                        </Typography>
                      </Grid>

                      {/* Package Type */}
                      <Grid item xs={8}>
                        <Typography color={alpha(theme.palette.common.black, 0.75)}>
                          {`${translate('customer.membership.package')}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography color={theme.palette.common.black}>
                          {invoice?.type_order}
                        </Typography>
                      </Grid>

                      {/* Discount if applicable */}
                      {invoice?.discount && (
                        <>
                          <Grid item xs={8}>
                            <Typography color={alpha(theme.palette.common.black, 0.75)}>
                              {`${translate('memberships.discount')}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography color={theme.palette.common.black}>
                              {`${invoice.discount}%`}
                            </Typography>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={8}>
                        <Typography color={alpha(theme.palette.common.black, 0.75)}>
                          {`${translate('invoice.total')}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography color={theme.palette.common.black}>
                          {`${fCurrency(invoice?.total_price || 0)} ${invoice?.currency}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          )}
        </MembershipLayout>
      </Container>
    </>
  );
}
