import { ReactNode, useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
// hooks
import { useAuthContext } from './useAuthContext';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

type VerificationGuardProps = {
  children: ReactNode;
};

// The key to store the redirect path in localStorage
const REDIRECT_PATH_KEY = 'auth_redirect_path';

/**
 * VerificationGuard component checks if a user is verified.
 * If not, they are redirected to the confirmation page.
 *
 * @param {ReactNode} children - The content to display if the user is verified.
 *
 * @returns {JSX.Element} - The children if the user is verified, otherwise redirects to confirmation page.
 */
export default function VerificationGuard({ children }: VerificationGuardProps) {
  const { user, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Only redirect if not already on the confirmation page
  const isConfirmationPage = pathname.includes(PATH_AUTH.confirmation);

  useEffect(() => {
    // Only navigate if the user is authenticated, not verified, and not already on the confirmation page
    if (isAuthenticated && user && !user.is_verified && !isConfirmationPage) {
      // Store the current path before redirecting
      localStorage.setItem(REDIRECT_PATH_KEY, pathname);
      navigate(PATH_AUTH.confirmation);
    }
  }, [isAuthenticated, user, navigate, isConfirmationPage, pathname]);

  // If the user is not verified and not on the confirmation page, we return null
  // This prevents a flash of content before redirect
  if (isAuthenticated && user && !user.is_verified && !isConfirmationPage) {
    return null;
  }

  return <>{children}</>;
}
