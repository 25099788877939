import {
  Typography,
  Grid,
  Button,
  Stack,
  Box,
  useTheme,
  Dialog,
  SwipeableDrawer,
} from '@mui/material';
import Latex from 'react-latex-next';
import styles from './AiChat.module.css';
import Iconify from 'src/components/iconify';

import { useEffect, useState, useRef } from 'react';
// utils
import { IExercises, IOtherPrincipals, IAIResponses } from 'src/@types/ai';
import AIExercises from './Exercises';
import Puller from './Puller';
import { CustomAvatar } from 'src/components/custom-avatar';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useLocales } from 'src/locales';
import useResponsive from 'src/hooks/useResponsive';
import ButtonAnimate from 'src/components/animate/ButtonAnimate';

import LoadingDots from 'src/components/loading-dots';
import { LatexWithMarkdown } from 'src/pages/dashboard/ai_helper/formattingHelper';

type Props = {
  goChat: boolean;
  handleQuestion: (question: string, dontGenerateExecises?: boolean) => void;
  endResponses: number;
  setEndResponses: (value: number) => void;
  generateExercises: (exercises: IExercises[], prompt: string, difficulty?: string) => void;
  prompts: IAIResponses[];
  exercises: IExercises[];
  isLoadingExercises: boolean;
  isLoading: boolean;
  follow_up_questions: string[];
  other_principals: IOtherPrincipals[];
  allowReview: boolean;
  removeHelperText: (value: boolean) => void;
  override_minHeight?: number;
};

export default function AiChat({
  isLoading,
  endResponses,
  setEndResponses,
  handleQuestion,
  generateExercises,
  isLoadingExercises,
  prompts,
  exercises,
  follow_up_questions,
  override_minHeight = 60,
}: Props) {
  const { user } = useAuthContext();
  const isMobile = !useResponsive('up', 1200);

  const [open, setOpen] = useState(false);
  const endOfMessagesRef = useRef<HTMLDivElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openImageDialogue, setOpenImageDialogue] = useState(false);
  const [imageLink, setImageLink] = useState('');

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          width: '100%',
          minHeight: `${override_minHeight}%`,
          maxHeight: 'calc(100vh - 200px)',
          height: 'auto',
          maxWidth: '1400px',
        }}
      >
        {prompts.map((prompt, promptIndex) => (
          <>
            {/* User messages - Only show if prompt is not empty */}
            {prompt.prompt && (
              <Grid item xs={12} key={promptIndex} pl={4} mb={'12px'}>
                <Stack direction="row" justifyContent={'flex-end'} position={'relative'} pr={6}>
                  {prompt.isFile ? (
                    <Typography
                      variant="h6"
                      sx={{
                        padding: '2px',
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.secondary.contrastText,
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `1px solid ${theme.palette.primary.main}`,
                        boxShadow: `0px 2px 0px ${theme.palette.primary.main}`,
                        borderRadius: theme.palette.borders.borderRadius,
                        display: 'flex',
                        height: '300px',
                        width: isMobile ? '80%' : '60%', // Ensuring it takes full width of the parent or adjust accordingly
                        maxWidth: isMobile ? '80%' : '60%',
                        position: 'relative', // Needed for the pseudo-element
                        '&:after': {
                          content: '""',
                          position: 'absolute',
                          right: '-10px', // Adjust the position of the tail
                          top: '99%',
                          borderStyle: 'solid',
                          borderWidth: '20px 0px 10px 30px', // Adjust size of the triangle
                          borderColor: `transparent transparent transparent ${theme.palette.primary.main}`, // The border color on the left (tail side) should match the Box's background
                          // boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.25)', // Small shadow for the tail
                          transform: 'translateY(-42%) translateX(-20%) rotate(54deg)', // Adjust to align the tail properly
                        },
                      }}
                      className={styles.animatedText}
                    >
                      {prompt.fileLink && (
                        <>
                          {prompt.fileLink.includes('.pdf') ? (
                            <iframe
                              src={`https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
                                prompt.fileLink
                              )}`}
                              style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                aspectRatio: '16/9',
                                objectFit: 'contain', // Use 'contain' to maintain the aspect ratio and fill the container
                                objectPosition: 'center', // Center the image within the box
                              }}
                            ></iframe>
                          ) : (
                            <Box
                              onClick={() => (
                                setOpenImageDialogue(true), setImageLink(prompt.fileLink)
                              )}
                              sx={{
                                cursor: 'pointer',
                                zIndex: 2,
                                width: 1,
                                height: 1,
                                maxWidth: '100%',
                                maxHeight: '100%',
                                borderRadius: theme.palette.borders.borderRadius,
                                aspectRatio: '16/9',
                                overflow: 'hidden', // Ensure the image doesn't overflow the box
                              }}
                              component="img"
                              src={prompt.fileLink}
                              style={{
                                objectFit: 'contain', // Use 'contain' to maintain the aspect ratio and fill the container
                                objectPosition: 'center', // Center the image within the box
                              }} // Ensure image is scaled properly within the container
                            />
                          )}
                        </>
                      )}
                    </Typography>
                  ) : (
                    // USER PROMPT CHAT BOX
                    <Typography
                      variant="h6"
                      flexWrap="wrap"
                      sx={{
                        padding: isMobile ? '10px' : '12px',
                        position: 'relative',
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        borderRadius: theme.palette.borders.borderRadius,
                        border: `1px solid ${theme.palette.primary.main}`,
                        boxShadow: `0px 2px 0px ${theme.palette.primary.main}`,
                        width: 'fit-content',
                        height: 'fit-content',
                        maxHeight: '100%',
                        minWidth: '50px',
                        minHeight: '50px',
                        maxWidth: isMobile ? '80%' : '50%',
                        wordWrap: 'break-word',
                        '& .katex .base, & .katex .strut': {
                          display: 'initial !important',
                          whiteSpace: 'pre-wrap !important',
                        },
                        '&:after': {
                          content: '""',
                          position: 'absolute',
                          right: '-10px',
                          top: '99%',
                          borderStyle: 'solid',
                          borderWidth: '20px 0px 10px 30px',
                          borderColor: `transparent transparent transparent ${theme.palette.secondary.main}`,
                          transform: 'translateY(-42%) translateX(-20%) rotate(54deg)',
                        },
                      }}
                      className={styles.animatedText}
                    >
                      {/* <LatexWithMarkdown text={prompt.prompt} /> */}
                      <Latex>{` ${prompt.prompt} `}</Latex>
                      {/* <Latex>{`\\( ${prompt.prompt.replace(/ /g, '\\;')} \\)`}</Latex> */}
                    </Typography>
                  )}
                  <CustomAvatar
                    sx={{
                      width: '40px',
                      height: '40px',
                      position: 'absolute', // Position it relative to the Box
                      bottom: '-55px', // Adjust as necessary to position below the Typography :after effect
                      // left: '-10px',
                      right: '10px',
                      boxShadow: theme.palette.borders.boxShadowHoverPrimary,
                    }}
                    src={''}
                    alt={user?.first_name || ''}
                    name={user?.first_name || ''}
                    color="success"
                  />
                </Stack>
              </Grid>
            )}

            <>
              {/* AI messages */}
              {prompt.responses
                .slice(0, prompts.length - 1 === promptIndex ? endResponses : undefined)
                .map((response, index) => {
                  return (
                    <>
                      {/* Loading chat box */}
                      {isLoading &&
                      prompt.responses.length - 1 === index &&
                      prompts.length - 1 === promptIndex ? (
                        <Grid item xs={12} mb={'12px'}>
                          <Stack
                            position={'relative'}
                            direction="row"
                            justifyContent="flex-start"
                            pl={6}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: '20%',
                                // marginRight: '45%',
                                position: 'relative',
                                backgroundColor: theme.palette.groundingGrey.main,
                                borderRadius: theme.palette.borders.borderRadius,
                                border: theme.palette.borders.border,
                                boxShadow: theme.palette.borders.boxShadow,
                                padding: isMobile ? '10px' : '12px',
                                minWidth: '50px',
                                minHeight: '50px',
                                width: 'fit-content',
                                height: 'fit-content',
                                '&:after':
                                  prompt.responses.length - 1 === index
                                    ? {
                                        content: '""',
                                        position: 'absolute',
                                        left: '-5px', // Adjust the position of the tail
                                        top: '99%',
                                        borderStyle: 'solid',
                                        borderWidth: '20px 0px 10px 30px', // Adjust size of the triangle
                                        borderColor: `transparent transparent transparent ${theme.palette.groundingGrey.main}`,
                                        boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.25)', // Small shadow for the tail
                                        transform:
                                          'translateY(-30%) translateX(15%) rotate(110deg)', // Adjust to align the tail properly
                                      }
                                    : {},
                              }}
                            >
                              {/* LOADING DOTS */}
                              <LoadingDots />
                            </Box>

                            <CustomAvatar
                              color="primary"
                              sx={{
                                width: '40px',
                                height: '40px',
                                position: 'absolute', // Position it relative to the Box
                                bottom: '-55px', // Adjust as necessary to position below the Typography :after effect
                                // right: '0px',
                                left: '10px',
                                boxShadow: theme.palette.borders.boxShadow,
                              }}
                              src={'/assets/topper_icon.svg'}
                              alt={'Topper'}
                              name={'Topper'}
                            />
                          </Stack>
                        </Grid>
                      ) : (
                        <>
                          {/* AI messages */}
                          <Grid key={index} item xs={12} mb={'12px'}>
                            <Stack
                              position={'relative'}
                              direction="row"
                              justifyContent={'flex-start'}
                              pl={6}
                            >
                              <Typography
                                ref={endOfMessagesRef}
                                variant="h6"
                                sx={{
                                  paddingTop: '20%',
                                  padding: isMobile ? '10px' : '12px',
                                  position: 'relative',
                                  backgroundColor: theme.palette.groundingGrey.main,
                                  color: theme.palette.groundingGrey.contrastText,
                                  borderRadius: theme.palette.borders.borderRadius,
                                  border: theme.palette.borders.border,
                                  boxShadow: theme.palette.borders.boxShadow,
                                  width: 'fit-content',
                                  height: 'fit-content',
                                  maxHeight: '100%',
                                  minHeight: '50px',
                                  minWidth: '50px',
                                  maxWidth: isMobile ? '80%' : '60%',
                                  '&:after':
                                    prompt.responses.length - 1 === index
                                      ? {
                                          content: '""',
                                          position: 'absolute',
                                          left: '-5px', // Adjust the position of the tail
                                          top: '99%',
                                          borderStyle: 'solid',
                                          borderWidth: '20px 0px 10px 30px', // Adjust size of the triangle
                                          borderColor: `transparent transparent transparent ${theme.palette.groundingGrey.main}`,
                                          transform:
                                            'translateY(-30%) translateX(15%) rotate(110deg)', // Adjust to align the tail properly
                                        }
                                      : endResponses === index + 1 &&
                                        prompts.length - 1 === promptIndex &&
                                        prompt.responses.length - 1 !== index
                                      ? {
                                          content: '""',
                                          position: 'absolute',
                                          left: '-5px', // Adjust the position of the tail
                                          top: '99%',
                                          borderStyle: 'solid',
                                          borderWidth: '20px 0px 10px 30px', // Adjust size of the triangle

                                          borderColor: `transparent transparent transparent ${theme.palette.groundingGrey.main}`,
                                          transform:
                                            'translateY(-30%) translateX(15%) rotate(110deg)', // Adjust to align the tail properly
                                        }
                                      : {},
                                }}
                              >
                                <LatexWithMarkdown text={response} />
                                {/* TODO: Consider having the whole input in latex tags and then make the AI output not having to use tags to fix that sometimes the AI makes wrong tags and users see $$ signs */}
                                {/* <Latex>{`\\( ${response.replace(/ /g, '\\;')} \\)`}</Latex> */}
                              </Typography>
                              {prompt.responses.length - 1 === index &&
                              prompts.length - 1 === promptIndex ? (
                                <CustomAvatar
                                  color="primary"
                                  sx={{
                                    width: '40px',
                                    height: '40px',
                                    position: 'absolute', // Position it relative to the Box
                                    bottom: '-55px', // Adjust as necessary to position below the Typography :after effect
                                    // right: '0px',
                                    left: '10px',
                                    boxShadow: theme.palette.borders.boxShadow,
                                  }}
                                  src={'/assets/topper_icon.svg'}
                                  alt={'Topper'}
                                  name={'Topper'}
                                />
                              ) : (
                                endResponses === index + 1 &&
                                prompts.length - 1 === promptIndex &&
                                prompt.responses.length - 1 !== index && (
                                  <CustomAvatar
                                    color="primary"
                                    sx={{
                                      width: '40px',
                                      height: '40px',
                                      position: 'absolute', // Position it relative to the Box
                                      bottom: '-55px', // Adjust as necessary to position below the Typography :after effect
                                      left: '10px',
                                      boxShadow: theme.palette.borders.boxShadow,
                                    }}
                                    src={'/assets/topper_icon.svg'}
                                    alt={'Topper'}
                                    name={'Topper'}
                                  />
                                )
                              )}
                            </Stack>

                            {endResponses === index + 1 &&
                              prompts.length - 1 === promptIndex &&
                              prompt.responses.length - 1 !== index && (
                                <Stack
                                  position={'relative'}
                                  direction="row"
                                  justifyContent={'flex-start'}
                                  pl={10}
                                  pt={3}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    endIcon={<Iconify icon="ph:caret-right" />}
                                    sx={{
                                      backgroundColor: theme.palette.primary.main,
                                      color: theme.palette.primary.contrastText,
                                      borderRadius: theme.palette.borders.borderRadius,
                                      boxShadow: theme.palette.borders.boxShadow,
                                      transition:
                                        'background-color 0.3s, box-shadow 0.3s, transform 0.3s',
                                      '&:hover': {
                                        color: theme.palette.primary.contrastText,
                                        backgroundColor: theme.palette.primary.dark,
                                        boxShadow: theme.palette.borders.boxShadowHoverBlack,
                                      },
                                    }}
                                    onClick={() => {
                                      const amountToMove = endResponses + 1;
                                      // console.log(amountToMove);
                                      // console.log(prompt.responses.length + 1);
                                      if (amountToMove >= prompt.responses.length + 1) {
                                        setEndResponses(prompt.responses.length);
                                      } else {
                                        setEndResponses(amountToMove);
                                      }
                                    }}
                                  >
                                    {`${translate('ai.continue')}`}
                                  </Button>
                                </Stack>
                              )}

                            {/* {allowReview && <AIReview />} */}
                          </Grid>
                        </>
                      )}
                    </>
                  );
                })}
            </>
          </>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: '1400px',
          mt: 1,
          flexDirection: { xs: 'row', sm: 'row' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {follow_up_questions.length !== 0 && (
            <>
              {isMobile ? (
                <>
                  <ButtonAnimate
                    iconColor={theme.palette.error.contrastText}
                    isMobile={isMobile}
                    icon="ph:arrow-u-left-up"
                    textColor={theme.palette.error.contrastText}
                    text={`${translate('ai.chat.followUps')}`}
                    buttonColor={theme.palette.error.main}
                    onClick={() => setOpenDrawer(true)}
                    buttonColorHover={theme.palette.error.darker}
                    textColorHover={theme.palette.error.contrastText}
                    iconColorHover={theme.palette.error.contrastText}
                  />
                  <SwipeableDrawer
                    anchor="bottom"
                    open={openDrawer}
                    disableSwipeToOpen={true}
                    onOpen={() => {
                      setOpenDrawer(true);
                    }}
                    onClose={() => setOpenDrawer(false)}
                  >
                    <Puller onClose={() => setOpenDrawer(false)} />

                    <Box sx={{ pl: 2, pt: 4 }}>
                      {follow_up_questions.map((question, index) => (
                        <ButtonAnimate
                          isMobile={isMobile}
                          key={index}
                          text={question}
                          onClick={() => handleQuestion(question)}
                        />
                      ))}
                    </Box>
                  </SwipeableDrawer>
                </>
              ) : (
                <>
                  <Typography variant="subtitle2" color={theme.palette.error.main}>
                    {`${translate('ai.chat.followUps')}`}
                  </Typography>
                  {follow_up_questions.map((question, index) => (
                    <ButtonAnimate
                      isMobile={isMobile}
                      key={index}
                      text={question}
                      onClick={() => handleQuestion(question)}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </Box>
        <Box sx={{ marginLeft: 'auto' }}>
          <ButtonAnimate
            isMobile={isMobile}
            text={`${translate('ai.chat.exercisesNonMobile')}`}
            onClick={() => {
              setOpen(true);
              if (exercises.length === 0) {
                generateExercises([], prompts[prompts.length - 1].prompt);
              }
            }}
            buttonColor={theme.palette.info.main}
            icon={'ph:hammer'}
          />
        </Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        sx={{
          '& .MuiDialog-paper': {
            width: isMobile ? '95vw' : '75vw',
            height: isMobile ? '70vh' : '50vh',
            margin: '16px',
            borderRadius: theme.palette.borders.borderRadius,
            overflow: 'hidden',
          },
        }}
        PaperProps={{
          style: { borderRadius: theme.palette.borders.borderRadius },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        {!isLoadingExercises ? (
          <AIExercises
            currentQuestion={prompts[prompts.length - 1].prompt}
            handleQuestion={handleQuestion}
            isLoading={isLoadingExercises}
            exercises={exercises}
            generateExercises={generateExercises}
            open={open}
            onClose={setOpen}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              height: '100vh',
              width: '98%',
            }}
          >
            <LoadingDots />
          </Box>
        )}
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        sx={{ height: '100%', borderRadius: theme.palette.borders.borderRadius }}
        PaperProps={{
          style: { borderRadius: theme.palette.borders.borderRadius },
        }}
        open={openImageDialogue}
        onClose={() => setOpenImageDialogue(false)}
      >
        <Box
          sx={{
            width: 1,
            height: 1,
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: theme.palette.borders.borderRadius,
          }}
          component="img"
          src={imageLink}
          style={{ objectFit: 'fill' }} // Ensure image is scaled properly within the container
        />
      </Dialog>
    </>
  );
}
