import { IconButton, Tooltip, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify';
import { useLocation } from 'react-router-dom';

interface Props {
  handleGetHistory: () => void;
  setOpenHistory: (value: boolean) => void;
}

const AIHistoryButton = ({ setOpenHistory, handleGetHistory }: Props) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const location = useLocation();

  const handleClick = () => {
    handleGetHistory();
    setOpenHistory(true);
  };

  return (
    <>
      {location.pathname === '/ai' ? (
        <Tooltip title={String(translate('ai.chat.history'))}>
          <IconButton
            onClick={handleClick}
            sx={{
              minWidth: 48,
              height: 48,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              color: theme.palette.primary.main,
              transition: theme.transitions.create(['background-color', 'box-shadow'], {
                duration: theme.transitions.duration.shorter,
              }),
              '&:hover, &:active': {
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                boxShadow: `0 0 0 4px ${alpha(theme.palette.primary.main, 0.1)}`,
              },
              zIndex: 1050, // Ensure the button is above other elements
              // ...(isMobile && {
              //   position: 'fixed',
              //   bottom: theme.spacing(2),
              //   right: theme.spacing(2),
              // }),
            }}
          >
            <Iconify
              icon="ph:clock-counter-clockwise-bold"
              sx={{
                width: 28,
                height: 28,
                transition: theme.transitions.create(['transform'], {
                  duration: theme.transitions.duration.shorter,
                }),
                '@media (hover: hover)': {
                  '&:hover': {
                    transform: 'rotate(-15deg)',
                  },
                },
              }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
};

export default AIHistoryButton;
