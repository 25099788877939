import { combineReducers } from 'redux';

import storage from 'redux-persist/lib/storage';
// slices
import kpiReducer from './slices/kpi';
import statisticsReducer from './slices/statistics';
import calendarReducer from './slices/calendar';
import tutorReducer from './slices/tutor';
import customerReducer from './slices/customer';
import lessonReducer from './slices/lesson';
import lessonRequestReducer from './slices/lessonRequests';
import studentReducer from './slices/student';
import payslipReducer from './slices/payslip';
import invoiceReducer from './slices/invoice';
import transactionReducer from './slices/transaction';
import balanceReducer from './slices/balance';
import badgeReducer from './slices/badge';
import leaderboardReducer from './slices/leaderboard';
import participantReducer from './slices/participant';
import rewardReducer from './slices/reward';
import referralReducer from './slices/referral';
import teacherKpiReducer from './slices/teacher-kpi';
import pricingReducer from './slices/pricing';
import notesRecuder from './slices/notes';
import foldersReducer from './slices/folders';
import documentsReducer from './slices/documents';
// arrays
import chargeTypesReducer from './slices/chargesTypes';
import languageReducer from './slices/arrays/language';
import interestReducer from './slices/arrays/interest';
import high_schoolReducer from './slices/arrays/high_school';
import higher_education_institutionReducer from './slices/arrays/higher_education_institution';
import higher_education_programReducer from './slices/arrays/higher_education_program';
import programReducer from './slices/arrays/program';
import qualificationReducer from './slices/arrays/qualification';
import subjectReducer from './slices/arrays/subject';
import chatReducer from './slices/chat';
import notificationReducer from './slices/notification';
import aiReducer from './slices/ai';
import pushNotificationReducer from './slices/pushNotifications';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};
import { Action } from 'redux';
const appReducer = combineReducers({
  calendar: calendarReducer,
  customer: customerReducer,
  student: studentReducer,
  tutor: tutorReducer,
  kpi: kpiReducer,
  statistics: statisticsReducer,
  lesson: lessonReducer,
  payslip: payslipReducer,
  transaction: transactionReducer,
  badge: badgeReducer,
  leaderboard: leaderboardReducer,
  chargeTypes: chargeTypesReducer,
  balance: balanceReducer,
  invoice: invoiceReducer,
  high_school: high_schoolReducer,
  language: languageReducer,
  interest: interestReducer,
  higher_education_institution: higher_education_institutionReducer,
  higher_education_program: higher_education_programReducer,
  program: programReducer,
  qualification: qualificationReducer,
  subject: subjectReducer,
  participant: participantReducer,
  reward: rewardReducer,
  pricing: pricingReducer,
  referral: referralReducer,
  teacherKpi: teacherKpiReducer,
  lessonRequest: lessonRequestReducer,
  chat: chatReducer,
  notification: notificationReducer,
  ai: aiReducer,
  pushNotification: pushNotificationReducer,
  notes: notesRecuder,
  folders: foldersReducer,
  documents: documentsReducer,
});

const rootReducer: typeof appReducer = (state, action: Action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;
