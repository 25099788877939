import { useEffect, useState } from 'react';
import axios from '../../utils/axios';
import { useAuthContext } from 'src/auth/useAuthContext';
import SurveyPopover from './SurveyPopover';

export default function Surveys() {
  const [stopCallback, setStopCallback] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [survey, setSurvey] = useState('');
  const { user } = useAuthContext();

  const checkAvailableSurvey = async () => {
    try {
      const { data } = await axios.get(`/api/survey/get-available-survey/${user?.uid}`);
      if (data.survey) {
        setShowSurvey(true);
        setSurvey(data.survey);
        setStopCallback(true);
        console.log('Survey available ', data.survey, data.survey.satisfaction);
      }
    } catch (e) {
      setStopCallback(true);
    }
  };

  useEffect(() => {
    if (user) {
      // Basically the idea is that We want to look for available surveys, but not right after the user
      // logs into the website. We want to wait for a random time for a few minutes
      // However, it is possible that at that specific time, there is no survey available.
      // So, we want to keep checking for available surveys after a random time interval
      // But if we continuously check for available surveys, it will be a lot of requests
      // So, the waiting period between two checks will be increasing.
      const scheduleSurvey = (upperBound: number) => {
        if (stopCallback) return;
        // so, random delay is 1 minute more than Math.random()*upperBound minutes
        // if Math.random() = 0.5, upperbound = 4, then randomDelay = 0.5*4+1 = 3 minutes
        const randomDelay = Math.random() * upperBound * 60 * 1000 + 1 * 60 * 1000;
        setTimeout(() => {
          checkAvailableSurvey();
          if (upperBound < 20) scheduleSurvey(upperBound + Math.random() * 10);
          else scheduleSurvey(20);
        }, randomDelay);
      };
      scheduleSurvey(2);
    }
  }, [user]);

  return showSurvey ? (
    <SurveyPopover
      showSurvey={showSurvey}
      survey={survey}
      setShowSurvey={setShowSurvey}
      setStopCallback={setStopCallback}
    />
  ) : (
    <></>
  );
}
