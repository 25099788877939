import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { ITutorState } from '../../@types/tutor';
import { ITutor } from '../../@types/tutor';
import { IStudent } from '../../@types/student';

const initialState: ITutorState = {
  isLoading: false,
  error: null,
  tutors: [],
  tiers: [],
  tutor: null,
  activeTeacherEmails: [],
  tutorCourses: null,
  course: null,
};

const slice = createSlice({
  name: 'tutor',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET TutorS
    getTutorsSuccess(state, action) {
      state.isLoading = false;
      state.tutors = action.payload.data;
      state.error = null;
    },
    getTiersSuccess(state, action) {
      state.isLoading = false;
      state.tiers = action.payload;
      state.error = null;
    },
    // GET Tutor
    getTutorSuccess(state, action) {
      state.isLoading = false;
      state.tutor = action.payload;
      state.error = null;
    },
    //GET TUTOR EMAILS ACTIVE
    getActiveTeacherEmailsSuccess(state, action) {
      state.isLoading = false;
      state.activeTeacherEmails = action.payload;
      state.error = null;
    },
    // CREATE TUTOR
    createTutorSuccess(state, action) {
      state.isLoading = false;
      state.tutor = action.payload;
      state.tutors = [action.payload, ...(state.tutors ?? [])];
      state.error = null;
    },
    assignStudentsSuccess(state, action) {
      state.isLoading = false;
      if (state.tutor) state.tutor.students = action.payload;
      state.error = null;
    },
    uploadTutorProfileImageSuccess(state) {
      state.isLoading = false;
    },
    addReferralSuccess(state) {
      state.isLoading = false;
    },
    getTutorCoursesSuccess(state, action) {
      state.isLoading = false;
      state.tutorCourses = action.payload;
      state.error = null;
    },
    getTutorCourseSuccess(state, action) {
      state.isLoading = false;
      state.course = action.payload;
      state.error = null;
    },
    joinCourseSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      const updateCourse = action.payload;
      // Check if tutorCourses exists before updating
      // This prevents errors if courses haven't been fetched yet or if there was an error fetching them
      if (state.tutorCourses) {
        state.tutorCourses.found_courses.all_courses =
          state.tutorCourses.found_courses.all_courses.map((course) =>
            course.id === updateCourse.id ? updateCourse : course
          );
        state.tutorCourses.found_courses.match = state.tutorCourses.found_courses.match.map(
          (course) => (course.id === updateCourse.id ? updateCourse : course)
        );
      }
    },
    stopLoading(state) {
      state.isLoading = false;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getTutors(status: string | null) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const status_query = status ? `status=${status}` : 'null';
    try {
      const response = await axios.get(`/api/teachers?${status_query}`);
      dispatch(slice.actions.getTutorsSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getTiers() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/wage_tiers`);
      dispatch(slice.actions.getTiersSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getActiveTeacherEmails() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/teachers_active_email');
      dispatch(slice.actions.getActiveTeacherEmailsSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

// export function getActiveTutors(status: string | null) {
//   return async (dispatch: Dispatch) => {
//     dispatch(slice.actions.startLoading());
//     const status_query = status ? `status=${status}` : 'null';
//     try {
//       const response = await axios.get(`/api/teachers?${status_query}`);
//       const getActiveTutors = response.filter(student => student.active).length;
//       dispatch(slice.actions.getTutorsSuccess(response.data));
//       return true;
//     } catch (error) {
//       console.log(error);
//       let errorMessage = '';
//       if (error?.errors?.json._schema) {
//         errorMessage = error?.errors?.json._schema[0];
//       } else if (error?.errors?.json) {
//         errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
//       } else {
//         errorMessage = error?.message;
//       }
//       dispatch(slice.actions.hasError(errorMessage));
//       return false;
//     }
//   };
// }

export function getTutorsCustomer(email: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/get_all_teachers/${email}`);
      dispatch(slice.actions.getTutorsSuccess(response));
      return true;
      // console.log(response);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getTutorCourseDetails(hashed_id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log('Fetching course details for ID:', hashed_id);
      const response = await axios.get(`/api/course/${hashed_id}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.getTutorCourseSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message || 'Failed to fetch course details';
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

/**
 * This method assigns students to tutors
 * @param studentId Array of student Ids to assign to student
 * @param student_email Teacher Email
 * @returns the newly assigned students
 */
export function assignStudents(studentId: number[], teacher_email: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/take_students',
        {
          student_id: studentId,
          teacher_email: teacher_email,
        },
        { withCredentials: true }
      );
      // console.log(response);
      const ids = response.data.map((data: IStudent) => data.id.toString());
      dispatch(slice.actions.assignStudentsSuccess(ids));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getTutor(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/teachers/${id}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.getTutorSuccess(response.data));
      // console.log(response);
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function editTutor(form: Partial<ITutor>) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading);
    try {
      // console.log(form);
      const response = await axios.put(
        '/api/update_teacher',
        {
          ...form,
        },
        { withCredentials: true }
      );
      dispatch(slice.actions.getTutorSuccess(response.data));
      // console.log(response);
      return true; // Return true on success
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function uploadTutorProfileImage(
  file: File,
  fileName: string,
  fileType: string,
  teacher_id: string
) {
  return async (dispatch: Dispatch) => {
    try {
      // Step 2: The s3Client function validates your request and directs it to your Space's specified endpoint using the AWS SDK.
      if (file) {
        const form = new FormData();
        // console.log(file);
        form.append('file', file);
        form.append('fileName', fileName);
        form.append('fileType', fileType);
        form.append('teacher_id', teacher_id);

        await axios.put('/api/upload_photo', form, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        dispatch(slice.actions.uploadTutorProfileImageSuccess());
        return `https://tt-portal.ams3.digitaloceanspaces.com/tutor-images/${fileName}`;
      }
    } catch (error) {
      // console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return '';
    }
  };
}
export function createTutor(tutor: ITutor, subjects_create: string[], programs_create: string[]) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const age =
      (new Date().getTime() - new Date(tutor?.birthday).getTime()) / (1000 * 60 * 60 * 24);
    // console.log(`${new Date(tutor?.birthday).toISOString()}Z`);
    const tutorForSend = {
      ...tutor,
      subjects_create: subjects_create,
      programs_create: programs_create,
      photo: '',
      status: 'prospective',
      finished_highschool: true,
      age: Math.abs(Math.round(age / 365.25)),
      birthday: new Date(tutor?.birthday).toISOString(),
    };
    // console.log(tutor);
    // console.log(tutorForSend);
    try {
      const response = await axios.post(`/api/teachers`, tutorForSend, { withCredentials: true });
      // console.log(response);
      if (response.status === 404) {
        throw new Error('Tutor not found');
      } else {
        dispatch(slice.actions.createTutorSuccess(response));
        // console.log(response);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function addStripeAccount(teacher_id: string, type_session: string) {
  return async (dispatch: Dispatch) => {
    try {
      if (type_session === 'account_update') {
        window.open('https://dashboard.stripe.com/settings/payouts');
        return true;
      }
      const response = await axios.post(
        `/api/create_teacher_stripe_account`,
        {
          id: teacher_id,
          type_session: type_session,
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        window.location.replace(response.data.url);
      }
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function addReferral(teacher_id: string | number, reward_id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(
        `/api/referrals`,
        {
          teacher_id: teacher_id,
          reward_id: reward_id,
          earnings_date: new Date().toISOString(), // assuming the current date is needed
        },
        {
          withCredentials: true,
        }
      );
      // console.log(response);
      dispatch(slice.actions.addReferralSuccess());
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function joinTutorCourse(hashedId: string, tutorId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/api/join_course/${hashedId}/${tutorId}`,
        {},
        {
          withCredentials: true,
        }
      );
      dispatch(slice.actions.joinCourseSuccess(response.data));
      return { success: true, data: response.data };
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message || 'Failed to join course';
      }
      dispatch(slice.actions.hasError(errorMessage));
      return { success: false, error: errorMessage };
    }
  };
}

export function getTutorsCourses(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/course_teacher_search/${id}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.getTutorCoursesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getAllCourses() {
  return async (dispatch: Dispatch) => {
    const response = await axios.get('/api/get_all_courses', {
      withCredentials: true,
    });
    dispatch(slice.actions.getTutorCoursesSuccess(response.data));
    return true;
  };
}

export function quitCourse(studentId: number, reason: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/quit-course/`,
        {
          student_id: studentId,
          reason,
        },
        {
          withCredentials: true,
        }
      );

      dispatch(slice.actions.stopLoading());
      return { success: true, data: response.data };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error: error };
    }
  };
}

export function deleteTutorStripeAccount(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/teachers/delete_stripe_id/${id}`, {
        withCredentials: true,
      });
      dispatch(slice.actions.getTutorSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
