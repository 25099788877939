import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Card, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import { CloseIcon } from 'yet-another-react-lightbox/core';
import SurveyEmoji from './SurveyEmoji';

const DEFAULT_OPTIONS = ['Yes', "Don't know", 'No'];

export default function SurveySingleChoice({ selectedSurvey, submitSurvey, dismissSurvey }: any) {
  const { translate } = useLocales();
  const [selectedChoice, setSelectedChoice] = useState<string>('');
  const [options, setOptions] = useState<string[]>(DEFAULT_OPTIONS);

  const handleSurveyDismiss = () => {
    dismissSurvey();
  };

  useEffect(() => {
    if (selectedSurvey) {
      setOptions(selectedSurvey.options);
    }
  }, [selectedSurvey]);

  return (
    <Card style={{ borderRadius: 0, padding: '1px', width: '400px' }}>
      <Grid sx={{ pt: 2, pl: 2 }} container>
        <Grid item xs={11}>
          <Typography>{`${translate('survey.' + selectedSurvey.survey_message)}`}</Typography>
        </Grid>
        <Grid sx={{ alignment: 'flex-end' }} item xs={1}>
          <CloseIcon color="error" style={{ cursor: 'pointer' }} onClick={handleSurveyDismiss} />
        </Grid>
        {/* <Typography mt={2} fontSize={13}>
          {`${translate('survey.' + selectedSurvey.description)}`}
        </Typography> */}
      </Grid>
      {/* <CardContent> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Grid
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '20px',
            marginTop: '5px',
          }}
          container
          gap={7}
        >
          {options.map((r: string, indx: number) => (
            <Grid key={r.toString()} item>
              {/* <Button
                sx={{ borderRadius: 0, minWidth: '250px' }}
                onClick={() => {
                  setSelectedChoice(r);
                }}
                variant={selectedChoice === r ? 'contained' : 'outlined'}
              >
                {`${translate(`survey.${r}`)}`}
              </Button> */}
              <Box onClick={() => submitSurvey(r)}>
                <SurveyEmoji
                  survey={selectedSurvey}
                  selected={selectedChoice === r}
                  optionName={r}
                  optionIndx={indx}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* <Box sx={{ mt: '10px' }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Button
              disabled={selectedChoice === ''}
              onClick={handleSurveySubmit}
              variant="contained"
              sx={{ borderRadius: 1 }}
            >
              {`${translate('survey.submit')}`}
            </Button>
          </Grid>
        </Box> */}
      {/* </CardContent> */}
    </Card>
  );
}
